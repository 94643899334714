import React, {useState} from "react";
import {
    ActionFunction,
    defer, json,
    LoaderFunction,
    matchPath,
    Navigate,
    redirect, useActionData,
    useLoaderData, useNavigate, useNavigation, useParams,
    useSubmit
} from "react-router-dom";
import services from "../../services/Services";
import ModalResponsive from "../../components/Modals/ModalResponsive";
import ModalFragmentHeader from "../../components/Modals/ModalFragmentHeader";
import classes from "../Profile/RequiredVerification.module.css";
import FormButton from "../../components/UI/Buttons/FormButton";
import {useAppSelector} from "../../hooks/hooks";
import {selectUser} from "../../store/slices/userSlice";

const VerifyPage: React.FC = () => {
    const currentUser = useAppSelector(selectUser);
    const {username} = useParams() as {username: string};
    const loaderData = useLoaderData() as {detail: string, status: number} | undefined
    const actionData = useActionData() as {detail: string, status: number} | undefined
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const navigate = useNavigate();
    const submit = useSubmit();

    if ((!!currentUser && currentUser.isVerified) || currentUser === null) {
        return <Navigate to={`/profil/${username}`}/>
    }
    // action: `/profil/${username}/weryfikacja`,
    const handleSubmit = async () => {
        setHasSubmitted(true)
        submit({}, {method: "POST", encType: "application/json"})
    }

    const handleClose = () => {
        if (!!loaderData && loaderData.status === 200) {
            navigate(`/profile/${username}`)
        }
    }

    let content = <>
        <p>Nie wiele dzieli Cię od rozpoczęcia niesamowitej przygody z YooCard!</p>
        <p>Potwierdź rejestrację konta za pomocą otrzymanego od nas maila aby móc rozpocząć budowę swojego profilu.</p>
        <FormButton type="button" onClick={handleSubmit}>Wyślij ponownie</FormButton>
    </>


    if (!!loaderData && loaderData.status === 200) {
        content = <>
            <p>Pomyślnie zweryfikowano konto.</p>
            <p>Dziękujemy za korzystanie z naszej platformy, od teraz możesz dodawać/edytować/usuwać swoje kafelki, a także zaaktualizować swoje zdjęcie profilowe.</p>
            <FormButton>Zamknij</FormButton>
        </>
    }

    if (!!loaderData && loaderData.status === 404) {
        content = <>
            <p>Twój link aktywacyjny wygasł.</p>
            <p>Kliknij wyślij ponownie aby otrzymać nowy link aktywacyjny.</p>
            <FormButton type="button" onClick={handleSubmit}>Wyślij ponownie</FormButton>
        </>
    }

    if (hasSubmitted) {
        content = <>
            <p>Przesłaliśmy nowy link aktywacyjny na podany przez Ciebie mail.</p>
            <p>Jeżeli wciąż masz problemy z potwierdzeniem konta napisz do nas maila na: biuro@yoocard.pl</p>
            <FormButton type="button" onClick={handleSubmit}>Wyślij ponownie</FormButton>
        </>
    }

    return <ModalResponsive onClose={handleClose}>
        <ModalFragmentHeader className={classes.header} onClose={handleClose}>Informacja</ModalFragmentHeader>
        <div className={classes.container}>
            {content}
        </div>
    </ModalResponsive>
}

export default VerifyPage;


export const loader: LoaderFunction = async ({request, params}) => {
    const {username} = params as {username: string}
    const url = new URL(request.url);
    const matched_url = matchPath(`/profil/${username}/weryfikacja/:token`, url.pathname)
    const token = matched_url?.params.token || ""
    if (token) {
        try {
            await services.auth.verify(token)
            return defer({detail: "Pomyślnie zweryfikowano konto", status: 200})
        } catch (error) {
            return null;
        }
    }
    return null;

}


export const action: ActionFunction = async ({request}) => {
    try {
        await services.auth.resendVerify()
        return json({detail: "Mail z linkiem aktywacyjnym został przesłany.", status: 200});
    } catch (error) {
        return error
    }
}