import React from "react";

const SVGEye: React.FC = () => {
    return <svg
        fill="#000000"
        width="26px"
        height="26px"
        viewBox="0 -16 544 544"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>hide</title>
        <path
            d="M108 60L468 420 436 452 362 378Q321 400 272 400 208 400 154 361 99 322 64 256 79 229 102 202 124 174 144 160L76 92 108 60ZM368 256Q368 216 340 188 312 160 272 160L229 117Q254 112 272 112 337 112 392 152 446 192 480 256 474 269 461 288 448 307 434 322L368 256ZM272 352Q299 352 322 338L293 309Q283 312 272 312 249 312 233 296 216 279 216 256 216 247 220 236L190 206Q176 229 176 256 176 296 204 324 232 352 272 352Z"/>
    </svg>
}

export default SVGEye;