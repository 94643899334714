import {Action, configureStore, ThunkAction} from "@reduxjs/toolkit";
import userReducer  from "./slices/userSlice";
import authSlice from "./slices/authSlice";
import tileDraggableReducer from "./slices/tileDraggableSlice"
import {preloadedState} from "./preloadedState";

const store = configureStore({
    reducer: {
        user: userReducer,
        auth: authSlice,
        tileDraggable: tileDraggableReducer
    },
    preloadedState: preloadedState
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export default store;