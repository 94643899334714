import React, {Suspense, useState} from "react";
import {useAppSelector} from "../../hooks/hooks";
import {Await, useLocation, useNavigate, useNavigation, useRouteLoaderData} from "react-router-dom";
import IProfile, {IProfileLoaderData} from "../../schemas/Profile";
import IUser from "../../schemas/User";
import TileWrapper from "./Components/TileWrapper";

import classes from "./NewTile.module.css"
import NewTileFormModal from "./NewTileFormModal";
import {selectUser} from "../../store/slices/userSlice";
import {useTranslation} from "react-i18next";
import {IRoot} from "../../schemas/Lang/Root";

interface NewTileProps {
    profile: IProfile;
}

const NewTile: React.FC<NewTileProps> = ({profile}) => {
	const currentUser = useAppSelector(selectUser);
    const navigate = useNavigate()
    const { t } = useTranslation();
    const translationPaths = t('paths', { returnObjects: true }) as IRoot["paths"];
    const translationForms = t('forms', { returnObjects: true }) as IRoot["forms"];
    if (!currentUser) {
        return null;
    }

    if (!(["ADMIN", "SUPER_ADMIN"].includes(currentUser.role)) && currentUser.username !== profile.username) {
        return null;
    }

    return <button
        className={classes.button}
        onClick={() => navigate(`${translationPaths.profile.tiles.index}/${translationPaths.profile.tiles.new.index}`)}
        // onClick={() => console.log("CLICK")}
    >
        {translationForms.tiles.textButtons.newTile}
    </button>
}

export default NewTile;