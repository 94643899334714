import React from "react";
import IContactTile, {IContactTileCreate, IContactTileUpdate} from "../../../schemas/TileExtensions/ContactTile";
import {HTMLFormMethod} from "@remix-run/router";
import {Form, useActionData, useRouteLoaderData, useSubmit} from "react-router-dom";
import FormInput from "../../UI/Inputs/FormInput";
import useFormInputNew from "../../../hooks/use-form-input-new";
import {
    isTileEmailValid,
    isTileFullNameValid,
    isTilePhoneNumberValid,
    isTileTitleValid
} from "../../../validators/Tile";
import FormButton from "../../../components/UI/Buttons/FormButton";

import classes from "./TileForm.module.css";
import {TileTypeEnum} from "../../../schemas/Tile";
import FormInfo from "../../Forms/FormInfo";
import IProfile from "../../../schemas/Profile";
import FormInputPhone from "../../UI/Inputs/FormInputPhone";
import useFormInputPhone from "../../../hooks/use-form-input-phone";
import {useAppSelector} from "../../../hooks/hooks";
import {selectUser} from "../../../store/slices/userSlice";
import IUser from "../../../schemas/User";
import {useTranslation} from "react-i18next";
import {IRoot} from "../../../schemas/Lang/Root";

interface ContactTileFormProps {
    method: HTMLFormMethod;
    tile?: IContactTile;
}

const ContactTileForm: React.FC<ContactTileFormProps> = ({
    method,
    tile,
}) => {
    const submit = useSubmit()
    const actionData = useActionData() as { detail: string } | undefined;
    const { profile } = useRouteLoaderData("profile") as {profile: IProfile};
    const currentUser = useAppSelector(selectUser);
    const { t } = useTranslation();
    const translationForms = t('forms', { returnObjects: true }) as IRoot["forms"];

    const {
        value: titleValue,
        isTouched: isTouchedTitle,
        isActive: isActiveTitle,
        isValid: isValidTitle,
        hasError: hasErrorTitle,
        handleInputChange: handleInputChangeTitle,
        handleInputBlur: handleInputBlurTitle,
        handleInputFocus: handleInputFocusTitle,
        reset: resetTitle,
    } = useFormInputNew({
        initialValue: tile?.title,
        validateValue: isTileTitleValid
    })

    const {
        value: fullNameValue,
        isTouched: isTouchedFullName,
        isActive: isActiveFullName,
        isValid: isValidFullName,
        hasError: hasErrorFullName,
        handleInputChange: handleInputChangeFullName,
        handleInputBlur: handleInputBlurFullName,
        handleInputFocus: handleInputFocusFullName,
        reset: resetFullName,
    } = useFormInputNew({
        initialValue: tile ? tile.fullName : profile.fullName,
        validateValue: isTileFullNameValid
    })

    const {
        value: emailValue,
        isTouched: isTouchedEmail,
        isActive: isActiveEmail,
        isValid: isValidEmail,
        hasError: hasErrorEmail,
        handleInputChange: handleInputChangeEmail,
        handleInputBlur: handleInputBlurEmail,
        handleInputFocus: handleInputFocusEmail,
        reset: resetEmail,
    } = useFormInputNew({
        initialValue: tile ? tile.email : profile.email,
        validateValue: isTileEmailValid
    })

    const {
        value: phoneNumberValue,
        isTouched: isTouchedPhoneNumber,
        isActive: isActivePhoneNumber,
        isValid: isValidPhoneNumber,
        hasError: hasErrorPhoneNumber,
        handleInputChange: handleInputChangePhoneNumber,
        handleInputBlur: handleInputBlurPhoneNumber,
        handleInputFocus: handleInputFocusPhoneNumber,
        reset: resetPhoneNumber,
    } = useFormInputPhone({
        initialValue: tile ? tile.phoneNumber : profile.phoneNumber ? profile.phoneNumber : translationForms.signup.initial.phoneNumberPrefix,
        validateValue: isTilePhoneNumberValid
    })

    const formIsValid = isValidTitle && isValidFullName && isValidEmail

    const handleSubmitForm: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (!formIsValid) {
            handleInputBlurTitle()
            handleInputBlurFullName()
            handleInputBlurEmail()
            handleInputBlurPhoneNumber()
            return
        }
        // UŻYJ JSON Z: https://github.com/remix-run/remix/discussions/1959
        // const formData = new FormData(event.currentTarget)
        // formData.set("type", TileTypeEnum.phone)
        const iconUrl = process.env.REACT_APP_BACKEND_URL_STATIC as string + "/tiles/icons/contact.svg"
        const tileData: IContactTileCreate | IContactTileUpdate = {
            type: TileTypeEnum.contact,
            title: titleValue,
            fullName: fullNameValue,
            email: emailValue,
            phoneNumber: phoneNumberValue,
            iconUrl: iconUrl
        }
        if (!!currentUser && currentUser.id !== profile.id) {
            tileData.userId = profile.id
        }
        submit(JSON.stringify(tileData), {method: method, encType: "application/json"})
    }

    return <Form className={classes.form} method="POST" onSubmit={handleSubmitForm}>
        <FormInput
            id="title"
            name="title"
            type="text"
            required={true}
            value={titleValue}
            placeholder={translationForms.tiles.types.contact.labels.title}
            label={translationForms.tiles.types.contact.labels.title}
            isActive={isActiveTitle}
            isTouched={isTouchedTitle}
            hasError={hasErrorTitle}
            onChange={handleInputChangeTitle}
            onBlur={handleInputBlurTitle}
            onFocus={handleInputFocusTitle}
        />
        <FormInput
            id="full-name"
            name="full-name"
            type="text"
            required={true}
            value={fullNameValue}
            placeholder={translationForms.tiles.types.contact.labels.fullName}
            label={translationForms.tiles.types.contact.labels.fullName}
            isActive={isActiveFullName}
            isTouched={isTouchedFullName}
            hasError={hasErrorFullName}
            onChange={handleInputChangeFullName}
            onBlur={handleInputBlurFullName}
            onFocus={handleInputFocusFullName}
        />
        <FormInput
            id="email"
            name="email"
            type="email"
            required={true}
            value={emailValue}
            placeholder={translationForms.tiles.types.contact.labels.email}
            label={translationForms.tiles.types.contact.labels.email}
            isActive={isActiveEmail}
            isTouched={isTouchedEmail}
            hasError={hasErrorEmail}
            onChange={handleInputChangeEmail}
            onBlur={handleInputBlurEmail}
            onFocus={handleInputFocusEmail}
        />
        <FormInputPhone
            id="phone-number"
            name="phone-number"
            required={true}
            value={phoneNumberValue}
            placeholder={translationForms.tiles.types.contact.labels.phoneNumber}
            label={translationForms.tiles.types.contact.labels.phoneNumber}
            isActive={isActivePhoneNumber}
            isTouched={isTouchedPhoneNumber}
            hasError={hasErrorPhoneNumber}
            onChange={handleInputChangePhoneNumber}
            onBlur={handleInputBlurPhoneNumber}
            onFocus={handleInputFocusPhoneNumber}
        />
        {actionData && <FormInfo>{actionData.detail}</FormInfo>}
        <FormButton type="submit">{method === "POST" ? translationForms.tiles.submit.new : translationForms.tiles.submit.edit}</FormButton>
    </Form>
}

export default ContactTileForm;