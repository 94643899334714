import React, {ReactNode} from "react";
import classes from "./ModalOptionList.module.css";

export interface ModalOptionListProps {
	children: ReactNode[] | ReactNode,
	className?: string
}

const ModalOptionList: React.FC<ModalOptionListProps> = ({
	children,
	className
}) => {
	const optionListClassName: string = className ? `${classes.options} ${className}` : classes.options;

	const renderOptions = () => {
		if (children instanceof Array) {
			return children.map((element, index) => {
				return <li key={index}>
					{element}
				</li>
			})
		}
		else {
			return <li>{children}</li>
		}
	}

	return <ul className={optionListClassName}>
		{renderOptions()}
	</ul>;
}


// export interface ModalOptionListProps {
// 	modalOptionList: ModalOptionProps[],
// 	className?: string
// }

// const ModalOptionList: React.FC<ModalOptionListProps> = ({
// 	modalOptionList,
// 	className
// }) => {
// 	const optionListClassName: string = className ? `${classes.options} ${className}` : classes.options;
//
// 	const renderOptions = (props: ModalOptionProps[]) => {
// 		return props.map((optionProps, index) => {
// 			return <li key={index}>
// 				<ModalOption {...optionProps}/>
// 			</li>
// 		})
// 	}
//
// 	return <ul className={optionListClassName}>
// 		{renderOptions(modalOptionList)}
// 	</ul>;
// }

export default ModalOptionList;