import React from "react";

interface SVGUploadProps {
    className?: string;
}

const SVGShare: React.FC<SVGUploadProps> = ({className = ""}) => {
    return <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        fill="none"
        role="img"
        aria-hidden="false"
        aria-labelledby="ltclid4_title "
    >
        <title>Share</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.6464 3.85353L11 4.20708L11.7071 3.49998L11.3536 3.14642L8.35355 0.146423H7.64645L4.64645 3.14642L4.29289 3.49998L5 4.20708L5.35355 3.85353L7.5 1.70708V9.49998V9.99998H8.5V9.49998V1.70708L10.6464 3.85353ZM1 5.5L1.5 5H4V6H2V15H14V6H12V5H14.5L15 5.5V15.5L14.5 16H1.5L1 15.5V5.5Z"
            fill="currentColor"
        ></path>
    </svg>;
}

export default SVGShare;