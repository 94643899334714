import React, {ChangeEvent, HTMLInputTypeAttribute, useEffect, useState} from "react";


import classes from "./FormInput.module.css";
import useFormInput from "../../../hooks/use-form-input";
import {isTileEmailValid} from "../../../validators/Tile";
import SVGEye from "../../Icons/Eye";
import SVGEyeHide from "../../Icons/EyeHide";

interface FormInput2Props {
	id: string;
	name: string;
	type?: HTMLInputTypeAttribute;
	label?: string;
	required?: boolean;
	initialValue?: string;
	onValidate?: (value: string) => boolean;
	onValidationStatusChange?: (isError: boolean) => void;
	checkAvailability?: (value: string) => Promise<{isAvailable: boolean}>;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void
	className?: string;
}

const FormInput2: React.FC<FormInput2Props> = ({
	id,
	name,
	type = "text",
	label = 'Label',
	required = false,
	initialValue = "",
	onChange,
	onPaste,
	onValidate ,
	onValidationStatusChange,
	checkAvailability,
	className = ""
}) => {
	const [value, setValue] = useState<string>(initialValue)
	const [isTouched, setIsTouched] = useState<boolean>(false)
	const [isActive, setIsActive] = useState<boolean>(false)
	const [hideValue, setHideValue] = useState<boolean>(type === "password")
	const [error, setError] = useState("")

	const handleCheckAvailability = async (value: string) => {
		try {
			if (checkAvailability) {
				const { isAvailable } = await checkAvailability(value);
				if (!isAvailable) {
					return "zajęty.";
				}
			}
			return "";
		} catch (error) {
			console.error('Błąd podczas sprawdzania dostępności:', error);
			return "błąd podczas sprawdzania dostępności.";
		}
	};

	useEffect(() => {
		let timer: NodeJS.Timeout | null = null;

		if (required && value.trim() === "") {
			if (isTouched) {
				setError("wymagane pole.");
			}
			if (onValidationStatusChange) {
				onValidationStatusChange(true);
			}
		} else if (onValidate && !onValidate(value)) {
			if (isTouched) {
				setError("nie poprawna wartość.");
			}
			if (onValidationStatusChange) {
				onValidationStatusChange(true);
			}
		} else if (checkAvailability) {
			if (isTouched) {
				setError("");
			}
			if (onValidationStatusChange) {
				onValidationStatusChange(false);
			}

			timer = setTimeout(async () => {
				const newErrorMessage = await handleCheckAvailability(value);
				if (isTouched) {
					setError(newErrorMessage);
				}
				if (onValidationStatusChange) {
					onValidationStatusChange(!!newErrorMessage);
				}
			}, 500);
		} else {
			if (isTouched) {
				setError("");
			}
			if (onValidationStatusChange) {
				onValidationStatusChange(false);
			}
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [value, isTouched, onValidate]);

	const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(event.currentTarget.value)
		setIsActive(true)

		if (onChange) {
			onChange(event)
		}
	}

	const handleBlurInput = (event: React.FocusEvent<HTMLInputElement>) => {
		setIsTouched(true)
		setIsActive(false)
	}

	const handleFocusInput = (event: React.FocusEvent<HTMLInputElement>) => {
		setIsActive(true)
	}

	const [wasVibrated, setWasVibrated] = useState(false);
	const isVibrationSupported = 'vibrate' in navigator;

	let fieldClassName = classes.field;

	if (isActive || value) {
		fieldClassName += ` ${classes.active}`;
	}

	if (error) {
		fieldClassName += ` ${classes.error}`;
		if (!wasVibrated) {
			if (isVibrationSupported) {
				navigator.vibrate(200);
			}
			setWasVibrated(true);
		}
	}

	return (
		<div className={fieldClassName}>
			<input
				id={id}
				name={name}
				type={type !== "password" ? type : hideValue ? "password" : "text"}
				value={value}
				placeholder={label}
				required={required}
				onChange={handleChangeInput}
				onFocus={handleFocusInput}
				onBlur={handleBlurInput}
				onPaste={onPaste}
			/>
			<label htmlFor={id} className={error ? classes.error : undefined}>
				{required ? "*" : ""}{label}{error ? " - " + error : ""}
			</label>
			{type === "password" &&
				<button
					className={classes["eye-button"]}
					type="button"
					onClick={() => setHideValue(!hideValue)}
				>
					{hideValue ? <SVGEye/> : <SVGEyeHide/>}
				</button>
			}
		</div>
	);
};

export default FormInput2;