import React from "react";

import classes from "./ShareButton.module.css"
import SVGArrowToLeft from "../../Icons/ArrowToLeft";

interface ShareButtonProps {
    SVGStart: React.ReactNode;
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string,
}

const ShareButton: React.FC<ShareButtonProps> = ({
    SVGStart,
    children,
    onClick,
    className
}) => {
    const buttonClassName = className ? `${classes["button-share"]} ${className}` : classes["button-share"]

    return <button className={buttonClassName} onClick={onClick}>
        <div>
            {SVGStart}
            <div>
                <p>{children}</p>
            </div>
            <SVGArrowToLeft />
        </div>
    </button>;
}

export default ShareButton;