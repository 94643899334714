import React from "react";
import {HTMLFormMethod} from "@remix-run/router";
import IUrlTile, {IUrlTileCreate, IUrlTileUpdate} from "../../../schemas/TileExtensions/UrlTile";
import {Form, useActionData, useRouteLoaderData, useSubmit} from "react-router-dom";

import classes from "./TileForm.module.css";
import FormInfo from "../../Forms/FormInfo";
import FormInput from "../../UI/Inputs/FormInput";
import useFormInputNew from "../../../hooks/use-form-input-new";
import {isTileTitleValid, isTileUrlValid} from "../../../validators/Tile";
import {TileTypeEnum} from "../../../schemas/Tile";
import FormButton from "../../../components/UI/Buttons/FormButton";
import useFormInputUrl from "../../../hooks/use-form-input-url";
import generateIconUrl from "../../../utils/generateIconUrl";
import IProfile from "../../../schemas/Profile";
import {useAppSelector} from "../../../hooks/hooks";
import {selectUser} from "../../../store/slices/userSlice";
import {useTranslation} from "react-i18next";
import {IRoot} from "../../../schemas/Lang/Root";

interface PhoneTileFormProps {
    method: HTMLFormMethod;
    tile?: IUrlTile;
}


const UrlTileForm: React.FC<PhoneTileFormProps> = ({
    method,
    tile,
}) => {
    const submit = useSubmit();
    const actionData = useActionData() as { detail: string } | undefined;
    const { profile } = useRouteLoaderData("profile") as {profile: IProfile};
    const currentUser = useAppSelector(selectUser);
    const { t } = useTranslation();
    const translationForms = t('forms', { returnObjects: true }) as IRoot["forms"];

    const {
        value: titleValue,
        isTouched: isTouchedTitle,
        isActive: isActiveTitle,
        isValid: isValidTitle,
        hasError: hasErrorTitle,
        handleInputChange: handleInputChangeTitle,
        handleInputBlur: handleInputBlurTitle,
        handleInputFocus: handleInputFocusTitle,
        reset: resetTitle,
    } = useFormInputNew({
        initialValue: tile?.title,
        validateValue: isTileTitleValid
    })

    const {
        value: urlValue,
        isTouched: isTouchedUrl,
        isActive: isActiveUrl,
        isValid: isValidUrl,
        hasError: hasErrorUrl,
        handleInputChange: handleInputChangeUrl,
        handleInputBlur: handleInputBlurUrl,
        handleInputFocus: handleInputFocusUrl,
        reset: resetUrl,
    } = useFormInputUrl({
        initialValue: tile?.url,
        validateValue: isTileUrlValid
    })

    const formIsValid = isValidTitle && isValidUrl

    const handleSubmitForm: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (!formIsValid) {
            return
        }
        // UŻYJ JSON Z: https://github.com/remix-run/remix/discussions/1959
        // const formData = new FormData(event.currentTarget);
        // formData.set("type", TileTypeEnum.url);
        const tileData: IUrlTileCreate | IUrlTileUpdate = {
            type: TileTypeEnum.url,
            title: titleValue,
            url: urlValue,
            iconUrl: generateIconUrl(urlValue)
        }
        if (!!currentUser && currentUser.id !== profile.id) {
            tileData.userId = profile.id
        }
        submit(JSON.stringify(tileData), {method: method, encType: "application/json"})
    }

    return <Form className={classes.form} method="POST" onSubmit={handleSubmitForm}>
        <FormInput
            id="title"
            name="title"
            type="text"
            required={true}
            value={titleValue}
            placeholder={translationForms.tiles.types.header.labels.title}
            label={translationForms.tiles.types.header.labels.title}
            isActive={isActiveTitle}
            isTouched={isTouchedTitle}
            hasError={hasErrorTitle}
            onChange={handleInputChangeTitle}
            onBlur={handleInputBlurTitle}
            onFocus={handleInputFocusTitle}
        />
        <FormInput
            id="url"
            name="url"
            type="url"
            required={true}
            value={urlValue}
            placeholder={translationForms.tiles.types.link.labels.link}
            label={translationForms.tiles.types.link.labels.link}
            isActive={isActiveUrl}
            isTouched={isTouchedUrl}
            hasError={hasErrorUrl}
            onChange={handleInputChangeUrl}
            onBlur={handleInputBlurUrl}
            onFocus={handleInputFocusUrl}
        />
        {actionData && <FormInfo>{actionData.detail}</FormInfo>}
        <FormButton type="submit">{method === "POST" ? translationForms.tiles.submit.new : translationForms.tiles.submit.edit}</FormButton>
    </Form>
}

export default UrlTileForm;