import {Nullable} from "./Base";

export enum UserGenderEnum {
	male = "male",
	female = "female",
	other = "other"
}

export enum UserRoleEnum {
	user = "USER",
	admin = "ADMIN",
	superAdmin = "SUPER_ADMIN"
}

export interface IUserBase {
	[key: string]: any;
	fullName: string;
	username: string;
	email: string;
	gender: UserGenderEnum;
	phoneNumber?: string;
}

export interface IUserCreate extends IUserBase {
	password: string;
}

export interface IUserUpdate extends Partial<IUserBase> {}

export default interface IUser extends IUserBase {
	id: string;
	role: UserRoleEnum;
	isVerified: boolean;
	createdAt: Date;
	updatedAt: Date;
	verifiedAt: Date | null;
	pictureUrl?: string;
}

