import React from "react";
import classes from "./Auth.module.css";
import ResetPasswordForm from "../../components/Auth/ResetPasswordForm";
import {useAppSelector} from "../../hooks/hooks";
import {selectUser} from "../../store/slices/userSlice";
import {Navigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {IRoot} from "../../schemas/Lang/Root";

const ResetPasswordPage: React.FC = () => {
    const currentUser = useAppSelector(selectUser);
    const { t } = useTranslation();
    const translationPaths = t('paths', { returnObjects: true }) as IRoot["paths"];

    if (currentUser) {
        return <Navigate to={`/${translationPaths.profile.index}/${currentUser.username}`}/>
    }

    return <div className={classes.container}>
        <ResetPasswordForm className={classes.form}/>
    </div>
}

export default ResetPasswordPage;