import React, {useEffect, useReducer} from "react";
import {CountryData} from "react-phone-input-2";

export interface UseFormInputPhoneProps {
    initialValue?: string;
    validateValue?: (phoneNumber: string, format: string) => boolean;
}

export interface UseFormInputPhoneInterface {
    (props: UseFormInputPhoneProps): {
        value: string;
        format: string;
        ignore: boolean;
        isTouched: boolean;
        isActive: boolean;
        isValid: boolean;
        hasError: boolean;
        handleInputChange: (
            value: string,
            data: CountryData | {},
            event: React.ChangeEvent<HTMLInputElement>,
            formattedValue: string
        ) => void;
        handleInputBlur: () => void;
        handleInputFocus: () => void;
        reset: () => void;
    };
}

interface InitialStateInterface {
    value: string;
    format: string;
    isTouched: boolean;
    isActive: boolean;
    ignore: boolean;
}


const initialState: InitialStateInterface = {
    value: "",
    format: "",
    isTouched: false,
    isActive: false,
    ignore: false,
}

interface ActionStateInterface extends Partial<InitialStateInterface> {
    type: string;
}

const inputStateReducer = (state: InitialStateInterface, action: ActionStateInterface): InitialStateInterface => {
    switch (action.type) {
        case "CHANGE":
            return { ...state, value: action.value || "", format: action.format || "", ignore: action.ignore as boolean, isActive: true};
        case "BLUR":
            return { ...state, isTouched: true, isActive: false}
        case "FOCUS":
            return { ...state, isActive: true}
        case "RESET":
            return { ...initialState }
        default:
            return { ...state }
    }
}

const useFormInputPhone: UseFormInputPhoneInterface = ({
    initialValue="",
    validateValue
}) => {
    const [inputState, dispatch] = useReducer(inputStateReducer, { ...initialState, value: initialValue });

    let valueIsValid = true;
    if (validateValue && !inputState.ignore) {
        valueIsValid = validateValue(inputState.value, inputState.format);
    }

    const hasError = !valueIsValid && inputState.isTouched;

    const handleInputChange = (
        value: string,
        data: CountryData | {},
        event: React.ChangeEvent<HTMLInputElement>,
        formattedValue: string
    ) => {
        if ("format" in data) {
            if (data.name === "Qatar") {
                dispatch({ type: "CHANGE", value: value, format: "", ignore: true})
            } else {
                dispatch({ type: "CHANGE", value: value, format: data.format, ignore: false})
            }
        } else {
            dispatch({ type: "CHANGE", value: value, format: "", ignore: false})
        }
    }

    const handleInputBlur = () => {
        dispatch({ type: "BLUR" })
    }

    const handleInputFocus = () => {
        dispatch({ type: "FOCUS" })
    }

    const reset = () => {
        dispatch({ type: "RESET" })
    }

    return {
        value: inputState.value,
        format: inputState.format,
        ignore: inputState.ignore,
        isTouched: inputState.isTouched,
        isActive: inputState.isActive,
        isValid: valueIsValid,
        hasError,
        handleInputChange,
        handleInputBlur,
        handleInputFocus,
        reset,
    }
}

export default useFormInputPhone;