import React from "react";
import {isRouteErrorResponse, useParams, useRouteError} from "react-router-dom";

import classes from "./Error.module.css";
import {IRoot} from "../../schemas/Lang/Root";
import {useTranslation} from "react-i18next";

interface ProfileErrorPageProps {}

const ProfileErrorPage: React.FC<ProfileErrorPageProps> = () => {
    const error = useRouteError() as {detail: string, status: number};
    const {username} = useParams() as { username: string }
    const { t } = useTranslation();
    const translationError = t('error.profile.notFound', { username: username }) as IRoot["error"]["profile"]["notFound"];

    if (error.status === 404) {
        return <div className={classes.error} id="error-page">
            <h3>{translationError}</h3>
        </div>
    }

    if (isRouteErrorResponse(error)) {
        if (error.status === 401) {
            // ...
        } else if (error.status === 404) {
            return <div className={classes.error} id="error-page">
                <h1>{translationError}</h1>
            </div>
        }

        return (
            <div className={classes.error} id="error-page">
                <h1>Oops! {error.status}</h1>
                <p>{error.statusText}</p>
                {error.data?.message && (
                    <p>
                        <i>{error.data.message}</i>
                    </p>
                )}
            </div>
        );
    } else if (error instanceof Error) {
        return (
            <div className={classes.error} id="error-page">
                <h1>Oops! Niespodziewany błąd.</h1>
                <p>Coś poszło nie tak.</p>
                <p>
                    <i>{error.message}</i>
                </p>
            </div>
        );
    } else {
        return <></>;
    }
}

export default ProfileErrorPage;