import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactCrop, {Crop} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {PercentCrop, PixelCrop} from "react-image-crop/src/types";
import FormButton from "../Buttons/FormButton";

import classes from "./ImageCropper.module.css";
import Cropper, {Area} from "react-easy-crop";
import {classNames} from "react-easy-crop/helpers";
import {useTranslation} from "react-i18next";
import {IRoot} from "../../../schemas/Lang/Root";

interface ImageCropperProps {
    imageSrc: string;
    onCropComplete: (file: File) => void;
}

const ImageCropper: React.FC<ImageCropperProps> = ({imageSrc, onCropComplete}) => {
    const [crop, setCrop] = useState<{x: number, y: number}>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<number>(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const { t } = useTranslation();
    const translationPaths = t('paths', { returnObjects: true }) as IRoot["paths"];
    const translationForms = t('forms', { returnObjects: true }) as IRoot["forms"];

    const onCropChange = useCallback((newCrop: { x: number; y: number }) => {
        setCrop(newCrop);
    }, []);

    const onZoomChange = useCallback((newZoom: number) => {
        setZoom(newZoom);
    }, []);

    const handleCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCropImage = async () => {
        if (!croppedAreaPixels) {
            console.error("Brak danych do przycinania obrazu");
            return;
        }
        try {
            const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
            onCropComplete(croppedImage);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.wrapper}>
                <Cropper
                    classes={{
                        containerClassName: classes.cropper,
                    }}
                    image={imageSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    initialCroppedAreaPercentages={{height: 100, width: 100, x: 25, y: 25}}
                    showGrid={false}
                    cropShape={"round"}
                    onCropChange={onCropChange}
                    onZoomChange={onZoomChange}
                    onCropComplete={handleCropComplete}
                />
            </div>
            <FormButton type="button" onClick={handleCropImage}>{translationForms.profile.picture.overlayButtons.submit}</FormButton>
        </div>
    )
}

export default ImageCropper;

const getCroppedImg = async (imageSrc: string, pixelCrop: Area): Promise<File> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = imageSrc;
        image.onload = () => {
            // Obliczanie skali, jeśli wymiary przekraczają 250px
            const maxDimension = 250;
            const scaleFactor = Math.min(maxDimension / pixelCrop.width, maxDimension / pixelCrop.height);

            // Ustawienie wymiarów płótna w zależności od tego, czy obraz jest skalowany
            const canvas = document.createElement('canvas');
            canvas.width = scaleFactor < 1 ? pixelCrop.width * scaleFactor : pixelCrop.width;
            canvas.height = scaleFactor < 1 ? pixelCrop.height * scaleFactor : pixelCrop.height;

            const ctx = canvas.getContext('2d');
            if (!ctx) {
                reject(new Error('Nie można uzyskać kontekstu 2D płótna'));
                return;
            }

            // Rysowanie i skalowanie obrazu na płótnie
            ctx.drawImage(
                image,
                pixelCrop.x,
                pixelCrop.y,
                pixelCrop.width,
                pixelCrop.height,
                0,
                0,
                canvas.width,
                canvas.height
            );

            canvas.toBlob(blob => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }
                const file = new File([blob], 'newFile.jpeg', { type: 'image/jpeg' });
                resolve(file);
            }, 'image/jpeg');
        };
        image.onerror = () => {
            reject(new Error('Failed to load image'));
        };
    });
};


// const getCroppedImg = async (imageSrc: string, pixelCrop: Area): Promise<File> => {
//     return new Promise((resolve, reject) => {
//         const image = new Image();
//         image.src = imageSrc;
//         image.onload = () => {
//             const maxDimension = 250;
//             if (width > maxDimension || height > maxDimension) {
//                 const scaleFactor = Math.min(maxDimension / width, maxDimension / height);
//                 width = width * scaleFactor;
//                 height = height * scaleFactor;
//             }
//
//             const canvas = document.createElement('canvas');
//             canvas.width = pixelCrop.width;
//             canvas.height = pixelCrop.height;
//             const ctx = canvas.getContext('2d');
//
//             if (!ctx) {
//                 reject(new Error('Nie można uzyskać kontekstu 2D płótna'));
//                 return;
//             }
//
//             ctx.drawImage(
//                 image,
//                 pixelCrop.x,
//                 pixelCrop.y,
//                 pixelCrop.width,
//                 pixelCrop.height,
//                 0,
//                 0,
//                 pixelCrop.width,
//                 pixelCrop.height
//             );
//
//             canvas.toBlob(blob => {
//                 if (!blob) {
//                     reject(new Error('Canvas is empty'));
//                     return;
//                 }
//                 const file = new File([blob], 'newFile.jpeg', { type: 'image/jpeg' });
//                 resolve(file);
//             }, 'image/jpeg');
//         };
//         image.onerror = () => {
//             reject(new Error('Failed to load image'));
//         };
//     });
// };