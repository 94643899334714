import React, {useRef, useState} from "react";
import {
    ActionFunction,
    LoaderFunction,
    Navigate, redirect,
    useNavigate,
    useParams,
    useRouteLoaderData,
    useSubmit
} from "react-router-dom";
import ModalResponsive from "../../../newcomponents/Modals/ModalResponsive";
import ModalFragmentHeader from "../../../components/Modals/ModalFragmentHeader";
import ModalOption from "../../../components/Modals/ModalOption";
import {useAppSelector} from "../../../hooks/hooks";
import {selectUser} from "../../../store/slices/userSlice";
import IUser, {UserRoleEnum} from "../../../schemas/User";
import services from "../../../services/Services";
import navigation from "../../../components/Layout/Navigation";
import ImageCropper from "../../../components/UI/Image/ImageCropper";
import ModalOptionList from "../../../components/Modals/ModalOptionList";
import IProfile, {IProfileLoaderData} from "../../../schemas/Profile";
import {useTranslation} from "react-i18next";
import {IRoot} from "../../../schemas/Lang/Root";
import i18n from "../../../i18nConfig";

const PicturePage: React.FC = () => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const currentUser = useAppSelector(selectUser) as IUser;
    const {profile} = useRouteLoaderData("profile") as { profile: IProfile };
    const submit = useSubmit();

    const navigate = useNavigate();
    const { username } = useParams() as { username: string };
    const { t } = useTranslation();
    const translationPaths = t('paths', { returnObjects: true }) as IRoot["paths"];
    const translationForms = t('forms', { returnObjects: true }) as IRoot["forms"];

    const handleUpload = async (file: File) => {
        const formData = new FormData()
        formData.set("file", file)

        if ([UserRoleEnum.admin, UserRoleEnum.superAdmin].includes(currentUser.role) && currentUser.id !== profile.id) {
            formData.set("userId", profile.id)
            submit(formData, {method: "POST", encType: "multipart/form-data"})
        } else {
            submit(formData, {method: "POST", encType: "multipart/form-data"})
        }
    }

    const handleDelete = async () => {
        if ([UserRoleEnum.admin, UserRoleEnum.superAdmin].includes(currentUser.role) && currentUser.id !== profile.id) {
            submit({}, {action: `?userId=${profile.id}`, method: "DELETE"})
        } else {
            submit({}, {method: "DELETE"})
        }
    }

    const handleChangeInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files) {
            setSelectedImage(event.currentTarget.files[0])
        }
    }

    const handleClickInput = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    if (currentUser === null) {
        return <Navigate to={".."}/>
    }
    if (currentUser.username !== username && ![UserRoleEnum.admin, UserRoleEnum.superAdmin].includes(currentUser.role)) {
        return <Navigate to={".."}/>
    }

    if (selectedImage) {
        return <ModalResponsive onClose={() => navigate(`/${translationPaths.profile.index}/${username}`)}>
            <ModalFragmentHeader onReturn={() => setSelectedImage(null)}>{translationForms.profile.picture.overlayHeaders.adjustPhoto}</ModalFragmentHeader>
            <ImageCropper imageSrc={URL.createObjectURL(selectedImage)} onCropComplete={handleUpload}/>
        </ModalResponsive>
    }

    return <ModalResponsive onClose={() => navigate(`/${translationPaths.profile.index}/${username}`)}>
        <ModalFragmentHeader onClose={() => navigate(`/${translationPaths.profile.index}/${username}`)}>{translationForms.profile.picture.header}</ModalFragmentHeader>
        <ModalOptionList>
            <ModalOption onClick={handleClickInput}>
                {translationForms.profile.picture.textButtons.addNewPhoto}
                <input
                    type="file"
                    accept="image/png, image/jpeg"
                    style={{display: 'none'}}
                    ref={inputRef}
                    // value={selectedImage}
                    // value={selectedImage}
                    onChange={handleChangeInput}
                />
            </ModalOption>
            {profile.pictureUrl && <ModalOption onClick={handleDelete}>{translationForms.profile.picture.textButtons.deletePhoto}</ModalOption>}
        </ModalOptionList>
    </ModalResponsive>;
}

export default PicturePage;

export const action: ActionFunction = async ({request, params}) => {
    const { username } = params as { username: string }

    if (request.method === "POST") {
        const formData = await request.formData()
        const file = formData.get("file") as File | null
        const userId = formData.get("userId") as IProfile["id"] | null
        if (file && userId) {
            await services.users.uploadPicture(userId, file)
        } else if (file) {
            await services.account.uploadPicture(file)
        }
    }

    if (request.method === "DELETE") {
        const url = new URL(request.url);
        const params = url.searchParams;
        const userId = params.get("userId");
        if (userId) {
            await services.users.deletePicture(userId)
        } else {
            await services.account.deletePicture()
        }
    }

    return redirect(`/${i18n.t('paths.profile.index')}/${username}`)
}