import React, {useState} from "react";
import Card from "../UI/Card";
import {
    Form, isRouteErrorResponse,
    json,
    Link,
    useActionData,
    useAsyncError,
    useLocation,
    useNavigation,
    useRouteError
} from "react-router-dom";
import useInput from "../../hooks/use-input";
import FormInput from "../UI/Inputs/FormInput";

import classes from "./AuthForm.module.css";
import FormButton from "../UI/Buttons/FormButton";
import {ActionFunction} from "@remix-run/router/utils";
import services from "../../services/Services";
import useFormInput from "../../hooks/use-form-input";
import FormInput2 from "../UI/Inputs/FormInput2";
import {isTileTitleValid} from "../../validators/Tile";
import {isUserLoginValid, isUserPasswordValid} from "../../validators/User";
import {IAuthLogin} from "../../schemas/Auth";
import {AxiosResponse} from "axios";
import {IResponse} from "../../schemas/Response";
import {useTranslation} from "react-i18next";
import {IPaths} from "../../schemas/Lang/Paths";
import {IRoot} from "../../schemas/Lang/Root";

interface LoginFormProps {
    className?: string
}

const LoginForm: React.FC<LoginFormProps> = ({className}) => {
    const [isLoginValid, setIsLoginValid] = useState<boolean>(false);
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const { t } = useTranslation();
    const translationForms = t('forms', { returnObjects: true }) as IRoot["forms"];
    const translationPaths = t('paths', { returnObjects: true }) as IRoot["paths"];

    // const actionData = useActionData() as AxiosResponse<{detail: string}>;
    const actionData = useActionData() as IResponse;
    // console.log("LOGIN FORM")
    // console.log(isRouteErrorResponse(actionData))
    // console.log("actionData")
    // console.log(actionData.status)
    // console.log(actionData)
    const location = useLocation()
    const navigation = useNavigation();
    const isSubmitting = navigation.state === "submitting";
    const isValidForm = isLoginValid && isPasswordValid;

    return <Form method="POST" className={className}>
        <h2 className={classes.header}>{translationForms.login.header}</h2>
        <div className={classes["form-elements"]}>
            <FormInput2
                id="username"
                name="username"
                initialValue={location.state?.username}
                label={translationForms.login.labels.username}
                onValidationStatusChange={(isError) => setIsLoginValid(!isError)}
                onValidate={isUserLoginValid}
            />
            <FormInput2
                id="password"
                name="password"
                type="password"
                label={translationForms.login.labels.password}
                onValidationStatusChange={(isError) => setIsPasswordValid(!isError)}
                onValidate={isUserPasswordValid}
            />
            {actionData && <p className={classes.error}>{actionData && actionData.detail}</p>}
            <FormButton type="submit" disabled={!isValidForm || isSubmitting}>{translationForms.login.submit}</FormButton>
            {/*<Link className={classes["reset-password"]} to={"?mode=reset-password"}>Nie pamiętasz hasła?</Link>*/}
            <Link className={classes["switch-mode"]} to={`/${translationPaths.resetPassword.index}`}>{translationForms.login.paragraphs.notRememberPassword}</Link>
            <div className={classes.choice}>
                <div className={classes.separator}/>
                <div>{translationForms.login.paragraphs.or}</div>
                <div className={classes.separator}/>
            </div>
            <p className={classes["switch-mode"]}>{translationForms.login.paragraphs.notHaveAnAccount} <Link to={`/${translationPaths.signup.index}`}>{translationForms.login.paragraphs.signup}</Link></p>
        </div>
    </Form>
}

export default LoginForm;