import React, {useState} from "react";
import {HTMLFormMethod} from "@remix-run/router";
import IHeaderTile, {IHeaderTileCreate, IHeaderTileUpdate} from "../../../schemas/TileExtensions/HeaderTile";
import {
    Form,
    useActionData,
    useFetcher,
    useLocation,
    useNavigate,
    useParams,
    useRouteLoaderData,
    useSubmit
} from "react-router-dom";
import {TileTypeEnum} from "../../../schemas/Tile";
import FormButton from "../../../components/UI/Buttons/FormButton";
import FormInput from "../../UI/Inputs/FormInput";
import {isTileTitleValid} from "../../../validators/Tile";
import useFormInputNew from "../../../hooks/use-form-input-new";

import classes from "./TileForm.module.css";
import FormInfo from "../../Forms/FormInfo";
import ModalResponsiveForm from "../../Modals/ModalResponsiveForm";
import {useAppSelector} from "../../../hooks/hooks";
import {selectUser} from "../../../store/slices/userSlice";
import IProfile from "../../../schemas/Profile";
import {useTranslation} from "react-i18next";
import {IRoot} from "../../../schemas/Lang/Root";

interface HeaderTileFormProps {
    method: HTMLFormMethod;
    tile?: IHeaderTile;
}

const HeaderTileForm: React.FC<HeaderTileFormProps> = ({
    method,
    tile,
}) => {
    const submit = useSubmit();
    const actionData = useActionData() as { detail: string } | undefined;
    const { profile } = useRouteLoaderData("profile") as {profile: IProfile};
    const currentUser = useAppSelector(selectUser);
    const {username} = useParams() as { username: string };
    const { t } = useTranslation();
    const translationForms = t('forms', { returnObjects: true }) as IRoot["forms"];

    const {
        value: titleValue,
        isTouched: isTouchedTitle,
        isActive: isActiveTitle,
        isValid: isValidTitle,
        hasError: hasErrorTitle,
        handleInputChange: handleInputChangeTitle,
        handleInputBlur: handleInputBlurTitle,
        handleInputFocus: handleInputFocusTitle,
        reset: resetTitle,
    } = useFormInputNew({
        initialValue: tile?.title,
        validateValue: isTileTitleValid
    })

    const formIsValid = isValidTitle

    const handleSubmitForm: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (!formIsValid) {
            return
        }
        // UŻYJ JSON Z: https://github.com/remix-run/remix/discussions/1959
        // const formData = new FormData(event.currentTarget)
        // formData.set("type", TileTypeEnum.header)
        const tileData: IHeaderTileCreate | IHeaderTileUpdate = {
            type: TileTypeEnum.header,
            title: titleValue
        }
        if (!!currentUser && currentUser.id !== profile.id) {
            tileData.userId = profile.id
        }
        submit(JSON.stringify(tileData), {method: method, encType: "application/json"})
    }

    return <Form className={classes.form} method={method} onSubmit={handleSubmitForm}>
        <FormInput
            id="title"
            name="title"
            type="text"
            required={true}
            value={titleValue}
            placeholder={translationForms.tiles.types.header.labels.title}
            label={translationForms.tiles.types.header.labels.title}
            isActive={isActiveTitle}
            isTouched={isTouchedTitle}
            hasError={hasErrorTitle}
            onChange={handleInputChangeTitle}
            onBlur={handleInputBlurTitle}
            onFocus={handleInputFocusTitle}
        />
        {actionData && <FormInfo>{actionData.detail}</FormInfo>}
        <FormButton type="submit">{method === "POST" ? translationForms.tiles.submit.new : translationForms.tiles.submit.edit}</FormButton>
    </Form>
}

export default HeaderTileForm;