import React from "react";
import ModalResponsive from "../../Modals/ModalResponsive";
import ModalFragmentHeader from "../../Modals/ModalFragmentHeader";
import classes from "./MainSettingsModal.module.css";
import ModalOptionList from "../../Modals/ModalOptionList";
import ModalOption from "../../Modals/ModalOption";
import ToggleSwitchButton from "../../UI/Buttons/ToggleSwitchButton";
import {useAppSelector} from "../../../hooks/hooks";
import {selectIsTilesDraggable, setTilesDraggable} from "../../../store/slices/tileDraggableSlice";
import {AppDispatch} from "../../../store/store";
import {useDispatch} from "react-redux";
import {selectUser} from "../../../store/slices/userSlice";
import IUser, {UserRoleEnum} from "../../../schemas/User";
import UserNavMenu from "./NavMenu/UserNavMenu";
import GuestNavMenu from "./NavMenu/GuestNavMenu";
import AdminNavMenu from "./NavMenu/AdminNavMenu";

interface MainSettingsModalProps {
	onClose: () => void;
}

const NavMenuModal: React.FC<MainSettingsModalProps> = ({onClose}) => {
	const currentUser = useAppSelector<IUser | null>(selectUser)

	if (currentUser) {
		if ([UserRoleEnum.admin, UserRoleEnum.superAdmin].includes(currentUser.role)) {
			return <AdminNavMenu onClose={onClose}/>
		}
		return <UserNavMenu onClose={onClose}/>
	}
	return <GuestNavMenu onClose={onClose}/>
}

export default NavMenuModal;