import React from "react";

interface SVGCloseProps {
}

const SVGClose: React.FC<SVGCloseProps> = () => {
    return <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        data-testid="CloseButton"
        enableBackground="new 0 0 24 24"
        className="sc-gKsewC iPWGYb"
    >
        <path
            d="M13.3536 3.35357L13.7072 3.00001L13.0001 2.29291L12.6465 2.64646L13.3536 3.35357ZM2.64652 12.6465L2.29297 13L3.00008 13.7071L3.35363 13.3536L2.64652 12.6465ZM3.35363 2.64646L3.00008 2.29291L2.29297 3.00001L2.64652 3.35357L3.35363 2.64646ZM12.6465 13.3536L13.0001 13.7071L13.7072 13L13.3536 12.6465L12.6465 13.3536ZM12.6465 2.64646L2.64652 12.6465L3.35363 13.3536L13.3536 3.35357L12.6465 2.64646ZM2.64652 3.35357L12.6465 13.3536L13.3536 12.6465L3.35363 2.64646L2.64652 3.35357Z"
            fill="black"
        ></path>
    </svg>;
}

export default SVGClose;