import React, {useCallback, useEffect, useState} from "react";
import {AnyTile} from "../../schemas/Tile";
import {useAppSelector} from "../../hooks/hooks";
import {selectIsTilesDraggable} from "../../store/slices/tileDraggableSlice";
import {selectUser} from "../../store/slices/userSlice";
import {useLoaderData, useNavigate, useParams, useRouteLoaderData} from "react-router-dom";
import {DragDropContext, Draggable, DropResult, ResponderProvided} from "react-beautiful-dnd";
import {StrictModeDroppable} from "../../utils/StrictModeDroppable";
import Tile from "./Tile";
import {UserRoleEnum} from "../../schemas/User";
import services from "../../services/Services";

import classes from "./TileList.module.css";
import IProfile from "../../schemas/Profile";
import {useTranslation} from "react-i18next";
import {IRoot} from "../../schemas/Lang/Root";

interface TileListProps {
    tiles: AnyTile[];
}

const TileList: React.FC<TileListProps> = ({
    tiles: tilesProps,
}) => {
    const [tiles, setTiles] = useState<AnyTile[]>([]);
    const isTilesDraggable = useAppSelector(selectIsTilesDraggable);
    const currentUser = useAppSelector(selectUser);
    const { profile } = useRouteLoaderData("profile") as {profile: IProfile}
    const { username } = useParams() as {username: string};
    const [holdTimer, setHoldTimer] = useState<NodeJS.Timeout | null>(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const translationPaths = t('paths', { returnObjects: true }) as IRoot["paths"];

    let isEditable = false;

    if (!!currentUser
        && !!tiles.length
        && currentUser.id === tiles[0].userId
    ) {
        isEditable = true
    }

    if (!!currentUser && !!tiles && [UserRoleEnum.superAdmin, UserRoleEnum.admin].includes(currentUser.role)) {
        isEditable = true
    }

    if (!!tiles.length && !!currentUser) {
        if (![UserRoleEnum.superAdmin, UserRoleEnum.admin].includes(currentUser.role)
            && currentUser.username !== username) {
                isEditable = false
        }
    }

    useEffect(() => {
        setTiles([...tilesProps].sort((a, b) => a.position - b.position));
    }, [tilesProps]);

    const findTileByIndex = (index: number, tiles: AnyTile[]) => {
        if (index >= 0 && index < tiles.length) {
            return tiles[index];
        }
        return null;
    }

    const onDragEnd = async (result: DropResult, provided: ResponderProvided) => {
        const {source, destination} = result;

        if (!destination || source.index === destination.index) {
            return;
        }

        const draggedTile = findTileByIndex(source.index, tiles)
        if (!draggedTile) {
            return;
        }

        const newTiles = Array.from(tiles as AnyTile[]);
        const [reorderedTile] = newTiles.splice(source.index, 1);
        newTiles.splice(destination.index, 0, reorderedTile);
        if (!(!!draggedTile.id)) {
            return;
        }

        if (!!currentUser && username !== currentUser.username) {
            await services.tiles.updateForUser(profile.id, draggedTile.id, {position: destination.index + 1})
        } else {
            await services.tiles.setPosition(draggedTile.id, destination.index + 1)
        }


        setTiles(newTiles);
    };

    const handleTileHoldStart = useCallback((tile: AnyTile) => {
        if (!isTilesDraggable) {
            const timer = setTimeout(() => navigate(`${translationPaths.profile.tiles.index}/${tile.id}`), 500);
            setHoldTimer(timer);
        }
    }, [isTilesDraggable]);

    const handleTileHoldEnd = useCallback(() => {
        if (holdTimer) {
            clearTimeout(holdTimer);
            setHoldTimer(null);
        }
    }, [holdTimer]);

    const isEnabledToDragging = isEditable && isTilesDraggable;
    const isEnabledToEdit = isEditable && !isTilesDraggable;

    const tileElements = tiles.map((tile, index) => {
        let handleMouseDown: React.MouseEventHandler<HTMLLIElement> | undefined;
        let handleMouseUp: React.MouseEventHandler<HTMLLIElement> | undefined;
        let handleTouchStart: React.TouchEventHandler<HTMLLIElement> | undefined;
        let handleTouchEnd: React.TouchEventHandler<HTMLLIElement> | undefined;

        if (isEnabledToEdit) {
            handleMouseDown = () => handleTileHoldStart(tile)
            handleMouseUp = () => handleTileHoldEnd()
            handleTouchStart = () => handleTileHoldStart(tile)
            handleTouchEnd = () => handleTileHoldEnd()
        }

        return <Draggable
            key={tile.id}
            draggableId={tile.id}
            index={index}
            isDragDisabled={!isEnabledToDragging}
        >
            {(provided) => (
                <li
                    key={tile.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                    onContextMenu={(event) => event.preventDefault()}
                >
                    <Tile {...tile}/>
                </li>
            )}
        </Draggable>
    })

    return <>
        <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId="tiles">
                {(provided) => (
                    <ul className={classes.list} {...provided.droppableProps} ref={provided.innerRef}>
                        {tileElements}
                        {provided.placeholder}
                    </ul>
                )}
            </StrictModeDroppable>
        </DragDropContext>
    </>
}

export default TileList;