import React, {ContextType, Suspense, useContext, useEffect, useState} from "react";
import {
    ActionFunction,
    Await,
    defer,
    json,
    LoaderFunction, LoaderFunctionArgs,
    matchPath, Navigate,
    Outlet, Route, Routes,
    useLoaderData,
    useLocation, useOutletContext,
    useRouteLoaderData
} from "react-router-dom";
import Profile from "../../components/Profile/Profile";
import services from "../../services/Services";
import ProfileHeader from "../../components/Profile/Layout/ProfileHeader";

import classes from "./Profile.module.css";
import ProfileFooter from "../../components/Profile/Layout/ProfileFooter";
import IProfile, {IProfileLoaderData} from "../../schemas/Profile";
import {DeferFunction, DeferredData} from "@remix-run/router/dist/utils";
import ModalLoading from "../../components/Modals/ModalLoading";
import ProfileErrorPage from "./ProfileError";
// import TileList from "../../components/Tiles/TileList";
import UserPicture from "../../components/Users/UserPicture";
import NewTile from "../../components/Tiles/NewTile";
import {useAppSelector} from "../../hooks/hooks";
import {selectUser} from "../../store/slices/userSlice";
import IUser, {UserRoleEnum} from "../../schemas/User";
import {AnyTile} from "../../schemas/Tile";
import {ProfileContext} from "../../context/ProfileContext";
import {TileContext} from "../../context/TileContext";
import {current} from "@reduxjs/toolkit";
import TileList from "../../newcomponents/Tiles/TileList";
import TilePage, {loader as tilePageLoader} from "./Tile/Tile";
import NewTilePage from "./Tile/NewTile";
import EditTilePage, {action as tileAction, loader as tileLoader} from "./Tile/EditTile";
import EditTile from "../../components/Tiles/EditTile";
import ModalResponsive from "../../components/Modals/ModalResponsive";
import FormButton from "../../components/UI/Buttons/FormButton";
import ModalFragmentHeader from "../../components/Modals/ModalFragmentHeader";

interface ProfilePageProps {
}

const ProfilePage: React.FC<ProfilePageProps> = () => {
    const { profile } = useLoaderData() as ProfileLoaderData;

    return <div className={classes["page-container"]}>
        <ProfileHeader profile={profile}/>
        <main className={classes["main-container"]}>
            <div className={classes.container}>
                <div className={classes.header}>
                    <UserPicture className={classes.picture} profile={profile}/>
                    <h1 className={classes.username}>{profile.fullName}</h1>
                </div>
                <TileList
                    tiles={profile.tiles}
                    // isEditable={tileListIsEditable}
                    // isEditAble={
                    //     !!currentUser
                    //     && (currentUser.id === profile.id || [UserRoleEnum.admin, UserRoleEnum.superAdmin].includes(currentUser.role))}
                />
                <NewTile profile={profile}/>
            </div>
        </main>
        <ProfileFooter/>
        <Outlet/>
    </div>;
}

export default ProfilePage;

type ProfileLoaderData = {
    profile: IProfile
}

export const loader: LoaderFunction = async ({params}) => {
    const username = params.username as string;
    const profile = await services.profiles.getOneByUsername<IProfile>(username);
    return defer({profile});
}

export const action: ActionFunction = async ({params}) => {

}