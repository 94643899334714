import React, {useCallback, useEffect, useState} from "react";
import services from "../../../services/Services";
import IUrlTile from "../../../schemas/TileExtensions/UrlTile";
import IContactTile from "../../../schemas/TileExtensions/ContactTile";

import classes from "./TileIcon.module.css";

interface TileIconProps {
	iconUrl: IUrlTile["iconUrl"] | IContactTile["iconUrl"]
}

const TileIcon: React.FC<TileIconProps> = ({iconUrl}) => {
	const [iconSvg, setIconSvg] = useState<string>("");

	const fetchIcon = useCallback(async () => {
		if (!iconUrl) {
			return
		}

		const svgContent = await services.tiles.getIconByIconUrl(iconUrl);
		setIconSvg(svgContent)
	}, [iconUrl])

	useEffect(() => {
		fetchIcon();
	}, [fetchIcon]);

	return <div className={classes.icon} dangerouslySetInnerHTML={{__html: iconSvg}} />
}

export default TileIcon;