import IUser from "../schemas/User";
import {IAuth} from "../schemas/Auth";

// const loadFromLocalStorage = () => {
//     try {
//         // const currentUserData = localStorage.getItem("loggedUser")
//         // const expiredInData = localStorage.getItem("expireIn")
//         const isUserAuthenticatedData = localStorage.getItem("isUserAuthenticated")
//         let serializedState = undefined;
//         // if (currentUserData && expiredInData) {
//         if (isUserAuthenticatedData) {
//             // const currentUser: IUser = JSON.parse(currentUserData)
//             // const expiredIn: string = JSON.parse(expiredInData)
//             // serializedState = {
//             //     user: currentUser,
//             //     auth: {expireIn: expiredIn}
//             // }
//             const isUserAuthenticated: boolean = JSON.parse(isUserAuthenticatedData)
//             serializedState = {
//                 isUserAuthenticated: isUserAuthenticated
//             }
//         }
//         return serializedState;
//     } catch(e) {
//         console.error('Could not load state', e);
//         return undefined;
//     }
// };

const loadFromLocalStorage = () => {
    try {
        const isUserAuthenticatedData = localStorage.getItem("isUserAuthenticated")
        let serializedState = undefined;
        if (isUserAuthenticatedData) {
            const isUserAuthenticated: boolean = JSON.parse(isUserAuthenticatedData)
            serializedState = {
                auth: {
                    isUserAuthenticated
                }
            }
        }
        return serializedState;
    } catch(e) {
        console.error('Could not load state', e);
        return undefined;
    }
};

export const preloadedState = loadFromLocalStorage();