import React, {useState} from "react";
import SVGEye from "../../../components/Icons/Eye";
import SVGEyeHide from "../../../components/Icons/EyeHide";
import classes from "./FormInput.module.css";

interface FormInputProps {
    id?: string | undefined;
    name?: string | undefined;
    type?: React.HTMLInputTypeAttribute | undefined;
    value: string;
    placeholder?: string | undefined;
    required?: boolean | undefined;
    label?: string | undefined;
    isActive?: boolean | undefined;
    isTouched?: boolean | undefined;
    isAvailable?: boolean | undefined;
    hasError?: boolean | undefined;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement, Element>) => void | undefined;
    onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void | undefined;
}

const FormInput: React.FC<FormInputProps> = ({
    id,
    name,
    type,
    value,
    placeholder,
    required,
    label,
    isActive,
    isTouched,
    isAvailable,
    hasError,
    onChange,
    onFocus,
    onBlur,
}) => {
    const [hideValue, setHideValue] = useState(type === "password");
    const [wasVibrated, setWasVibrated] = useState<boolean>(false);

    const containerClassName = classes.container

    const handleHideValue = () => {
        setHideValue(!hideValue)
    }

    let fieldClassName = classes.field;

    if (isActive || value) {
        fieldClassName += ` ${classes.active}`;
    }

    if (hasError) {
        fieldClassName += ` ${classes.error}`;
        if (!wasVibrated) {
            if ('vibrate' in navigator) {
                navigator.vibrate(200);
            }
            setWasVibrated(true);
        }
    }

    let errorMessage = ""

    if (required && value.trim() === "" && isTouched) {
        errorMessage = "wymagane pole."
    } else if (hasError && typeof isAvailable === "boolean" && !isAvailable) {
        errorMessage = "zajęty."
    } else if (hasError) {
        errorMessage = "nie poprawna wartość"
    }

    return <div className={fieldClassName}>
        <input
            id={id}
            name={name}
            type={type !== "password" ? type : hideValue ? "password" : "text"}
            value={value}
            placeholder={placeholder}
            required={required}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
        />
        <label htmlFor={id} className={hasError ? classes.error : undefined}>
            {required ? "*" : ""}{label}{hasError ? " - " + errorMessage : ""}
        </label>
        {type === "password" &&
            <button
                className={classes["eye-button"]}
                type="button"
                onClick={handleHideValue}
            >
                {hideValue ? <SVGEye/> : <SVGEyeHide/>}
            </button>
        }
    </div>
}

export default FormInput;