import React from "react";

interface SVGArrowToLeftProps {
}

const SVGArrowToLeft: React.FC<SVGArrowToLeftProps> = () => {
    return <svg width="16px" height="16px" viewBox="0 0 16 16" enableBackground="new 0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.70714 1.29297L5.06069 1.64652L11.0607 7.64652V8.35363L5.06069 14.3536L4.70714 14.7072L4.00003 14.0001L4.35358 13.6465L10 8.00008L4.35358 2.35363L4.00003 2.00008L4.70714 1.29297Z"
            fill="black"
        ></path>
    </svg>;
}

export default SVGArrowToLeft;