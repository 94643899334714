import React, {useEffect} from "react";
import LoginForm from "../../components/Auth/LoginForm";
import services from "../../services/Services";
import {AppDispatch, RootState} from "../../store/store";
import {setAuthAction} from "../../store/actions/authActions";
import {ActionFunction, Navigate, useNavigate} from "react-router-dom";
import {useAppSelector} from "../../hooks/hooks";
import {selectUser} from "../../store/slices/userSlice";
import ModalLoading from "../../components/Modals/ModalLoading";

import classes from "./Auth.module.css";
import logo from "../../assets/logo2.png"
import {useTranslation} from "react-i18next";
import {IRoot} from "../../schemas/Lang/Root";

const LoginPage: React.FC = () => {
    const currentUser = useAppSelector(selectUser);
    const { t } = useTranslation();
    const translationPaths = t('paths', { returnObjects: true }) as IRoot["paths"];

    if (currentUser) {
        return <div className={classes.container}>
            <Navigate to={`/${translationPaths.profile.index}/${currentUser.username}`}/>
            <ModalLoading/>
        </div>
    }

    return <>
        <div className={classes.container}>
            <LoginForm className={classes.form}/>
        </div>
    </>
}

export default LoginPage;

export const action = (dispatch: AppDispatch): ActionFunction => async ({request}) => {
    const data = await request.formData();
    try {
        await services.auth.login(data);
        dispatch(setAuthAction(true));
        return null;
    } catch (error) {
        return error
    }
}
