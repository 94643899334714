import React, {Suspense, useEffect, useState} from "react";
import {Await, useLoaderData, useRouteLoaderData} from "react-router-dom";
import UserPicture from "../../Users/UserPicture";

import classes from "./ProfileHeader.module.css";
import IconButton from "../../UI/Buttons/IconButton";
import {ThreeDots} from "../../Icons/ThreeDots";
import IProfile, {IProfileLoaderData} from "../../../schemas/Profile";
import NavMenuModal from "../Components/NavMenuModal";

interface UserProfileNavigationProps {
    profile: IProfile
}

const ProfileHeader: React.FC<UserProfileNavigationProps> = ({profile}) => {
    // const { profile } = useLoaderData() as IProfileLoaderData;
    // const { userProfile } = useRouteLoaderData("user-profile") as UserProfileLoaderData;
    const [displayOverlay, setDisplayOverlay] = useState(false);

    const [scrolled, setScrolled] = useState(false); // stan informujący czy strona została przewinięta

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    let navigationClassName = classes.navigation;

    if (scrolled) {
        navigationClassName += ` ${classes.scrolled}`
    }

    return <>
        {displayOverlay && <NavMenuModal onClose={() => setDisplayOverlay(false)}/>}
        <header>
            <nav className={navigationClassName}>
                <IconButton className={classes.button} onClick={() => setDisplayOverlay(true)}>
                    <ThreeDots/>
                </IconButton>
                <div className={classes["username-wrapper"]}>
                    <p className={classes.username}>@{profile.username}</p>
                </div>
                <div className={classes["picture-wrapper"]}>
                    <UserPicture className={classes.picture} profile={profile}/>
                </div>
            </nav>
        </header>
    </>
    // return <>
    //     {/*{displayOverlay && <ModalProfileSingupOptions onClose={() => setDisplayOverlay(false)}/>}*/}
    //     {/*<div className={classes.container}>*/}
    //     {/*</div>*/}
    //     <div className={navigationClassName}>
    //         {displayOverlay && <NavMenuModal onClose={() => setDisplayOverlay(false)}/>}
    //         <IconButton className={classes.button} onClick={() => setDisplayOverlay(true)}>{<ThreeDots/>}</IconButton>
    //         <div className={classes["username-wrapper"]}>
    //             <Suspense>
    //                 <Await resolve={profile}>
    //                     {(loadedUserProfile) => <p className={classes.username}>@{loadedUserProfile.username}</p>}
    //                 </Await>
    //             </Suspense>
    //         </div>
    //         <div className={classes["picture-wrapper"]}>
    //             <Suspense>
    //                 <Await resolve={profile}>
    //                     {(loadedUserProfile) => <UserPicture className={classes.picture} profile={loadedUserProfile}/>}
    //                 </Await>
    //             </Suspense>
    //         </div>
    //     </div>
    // </>;
}

export default ProfileHeader;