import React from "react";
import {AppDispatch} from "../../store/store";
import {ActionFunction, Navigate, redirect} from "react-router-dom";
import services from "../../services/Services";
import {clearAuthAction} from "../../store/actions/authActions";
import {clearUserAction} from "../../store/actions/userActions";
import classes from "./Auth.module.css";
import ModalLoading from "../../components/Modals/ModalLoading";
import {useTranslation} from "react-i18next";
import {IRoot} from "../../schemas/Lang/Root";

const LogoutPage: React.FC = () => {
    const { t } = useTranslation();
    const translationPaths = t('paths', { returnObjects: true }) as IRoot["paths"];

    return <div className={classes.container}>
        <Navigate to={`/${translationPaths.login.index}`} />
        <ModalLoading/>
    </div>
}

export default LogoutPage;

export const action = (dispatch: AppDispatch): ActionFunction => async ({ request }) => {
    if (request.method === "POST") {
        await services.auth.logout();
        dispatch(clearUserAction());
        dispatch(clearAuthAction());
        // return null
        return redirect("./")
    }
}