import React from "react";
import ModalOverlay from "./ModalOverlay";

import classes from "./ModalResponsive.module.css";

interface ModalContentWrapperProps {
    onClose?: () => void;
    children: React.ReactNode;
    className?: string;
}

const ModalResponsive: React.FC<ModalContentWrapperProps> = ({onClose, children, className=""}) => {
    const contentClassName = className ? `${classes.container} ${className}` : classes.container;

    return <ModalOverlay className={classes.overlay} onClick={onClose}>
        <div className={contentClassName} onClick={(e) => e.stopPropagation()}>
            {children}
        </div>
    </ModalOverlay>;
}

export default ModalResponsive;