import React, { useState } from 'react';

import classes from './ToggleSwitchButton.module.css';

interface ToggleSwitchButtonProps {
	id: string;
	name: string;
	label: string;
	initialValue?: boolean;
	onToggle?: (isActive: boolean) => void;
	className?: string;
}

const ToggleSwitchButton: React.FC<ToggleSwitchButtonProps> = ({
	id,
	name,
	label,
	initialValue = false,
	onToggle,
	className = ''
}) => {
	const [isActive, setIsActive] = useState(initialValue);

	const handleToggle = () => {
		const newState = !isActive;
		setIsActive(newState);
		if (onToggle) {
			onToggle(newState);
		}
	};

	let switchButtonClassName = `${classes.switch} ${isActive ? classes.active : ''}`;
	if (className) {
		switchButtonClassName += ` ${className}`;
	}

	return <div className={switchButtonClassName}>
		<div className={`${classes.slider} ${isActive ? classes.active : ""}`}></div>
		<input
			id={id}
			name={name}
			type="checkbox"
			checked={isActive}
			onChange={handleToggle}
		/>
		<label htmlFor={id} className={classes.label}>{label}</label>
	</div>;

	// return <label className={switchButtonClassName}>
	// 	<input
	// 		id={id}
	// 		name={name}
	// 		type="checkbox"
	// 		checked={isActive}
	// 		onChange={handleToggle}
	// 	/>
	// 	<span className={`${classes.slider} ${classes.round}`}></span>
	// 	<span className={classes.label}>{label}</span>
	// </label>

	// return <div className={switchButtonClassName}>
	// 	<input
	// 		id={id}
	// 		name={name}
	// 		type="checkbox"
	// 		value={value}
	// 	/>
	// 	<label htmlFor={id}>{label}</label>
	// </div>
};

export default ToggleSwitchButton;