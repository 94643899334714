import React from "react";

import classes from "./TileTitle.module.css";
import {AnyTile} from "../../../schemas/Tile";

interface TileTitleProps {
	title: AnyTile["title"];
}

const TileTitle: React.FC<TileTitleProps> = ({title}) => {
	return <p className={classes.title}>{title}</p>
}

export default TileTitle;