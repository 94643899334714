import React, {FormEvent, useState} from "react";
import {
    ActionFunction,
    defer,
    LoaderFunction, redirect,
    useLoaderData,
    useLocation,
    useNavigate, useParams,
    useSubmit
} from "react-router-dom";
import services from "../../../services/Services";
import ModalFragmentHeader from "../../../components/Modals/ModalFragmentHeader";
import ModalOptionList from "../../../components/Modals/ModalOptionList";
import ModalOption from "../../../components/Modals/ModalOption";
import ModalResponsive from "../../../components/Modals/ModalResponsive";
import {AnyTile, AnyTileCreate, AnyTileUpdate, TileTypeEnum} from "../../../schemas/Tile";
import classes from "../../../components/Tiles/NewTileFormModal.module.css";
import TileForm from "../../../newcomponents/Tiles/Form/TileForm";
import {useTranslation} from "react-i18next";
import {IRoot} from "../../../schemas/Lang/Root";
import i18nConfig from "../../../i18nConfig";

const EditTilePage: React.FC = () => {
    const submit = useSubmit();
    const { username } = useParams() as {username: string}
    const navigate = useNavigate();
    const [ showEditForm, setShowEditForm ] = useState<boolean>(false);
    const { tile } = useLoaderData() as IEditTileLoader;
    const { t } = useTranslation();
    const translationForms = t('forms', { returnObjects: true }) as IRoot["forms"];
    const translationPaths = t('paths', { returnObjects: true }) as IRoot["paths"];

    const handleToggleHide = async () => {
        submit(JSON.stringify({active: !tile.active}), {method: "PATCH", encType: "application/json"})
    }

    const handleRemove = async () => {
        submit({}, {method: "DELETE", encType: "application/json"})
    }

    let headerTileForm = ""

    switch (tile.type) {
        case TileTypeEnum.header:
            headerTileForm = translationForms.tiles.overlayHeaders.tileEdit.section
            break
        case TileTypeEnum.contact:
            headerTileForm = translationForms.tiles.overlayHeaders.tileEdit.contact
            break
        case TileTypeEnum.url:
            headerTileForm = translationForms.tiles.overlayHeaders.tileEdit.link
            break
    }

    if (showEditForm) {
        return <ModalResponsive
            onClose={() => navigate(`/${translationPaths.profile.index}/${username}`)}
        >
            <ModalFragmentHeader className={classes.header} onReturn={() => setShowEditForm(false)}>
                {headerTileForm}
            </ModalFragmentHeader>
            <TileForm
                method="PATCH"
                tile={tile}
            />
        </ModalResponsive>
    }

    return <ModalResponsive onClose={() => navigate(`/${translationPaths.profile.index}/${username}`)}>
        <ModalFragmentHeader onClose={() => navigate(`/${translationPaths.profile.index}/${username}`)}>{translationForms.tiles.overlayHeaders.availableActions}</ModalFragmentHeader>
        <ModalOptionList>
            <ModalOption onClick={handleToggleHide}>{tile.active ? translationForms.tiles.textButtons.display.hide : translationForms.tiles.textButtons.display.show}</ModalOption>
            <ModalOption onClick={() => setShowEditForm(true)}>{translationForms.tiles.textButtons.edit}</ModalOption>
            <ModalOption onClick={handleRemove}>{translationForms.tiles.textButtons.delete}</ModalOption>
        </ModalOptionList>
    </ModalResponsive>
}

export default EditTilePage;

interface IEditTileLoader {
    tile: AnyTile;
}

export const loader: LoaderFunction = async ({params}) => {
    const tileId = params.tileId as string;
    const tile = await services.tiles.getOneById(tileId);
    return defer({tile});
}

export const action: ActionFunction = async ({request, params}) => {
    const tileId = params?.tileId
    const username = params?.username
    if (!username || !tileId) {
        return;
    }

    if (request.method === "PATCH") {
        const data: AnyTileUpdate = await request.json();
        if (data.userId) {
            await services.tiles.updateForUser(data.userId, tileId, data)
        } else {
            await services.tiles.update(tileId, data)
        }

    }

    if (request.method === "DELETE") {
        await services.tiles.delete(tileId)
    }

    return redirect(`/${i18nConfig.t("paths.profile.index") as string}/${username}`)
}