import { createSlice } from '@reduxjs/toolkit';
import {RootState} from "../store";

export interface ITileDragging {
	isTilesDraggable: boolean
}

export const initialState: ITileDragging = {
	isTilesDraggable: false,
};

export const tileDraggableSlice = createSlice({
	name: 'draggable',
	initialState: initialState,
	reducers: {
		setTilesDraggable: (state, action) => {
			state.isTilesDraggable = action.payload;
		},
	},
});

export const { setTilesDraggable } = tileDraggableSlice.actions;

export const selectIsTilesDraggable = (state: RootState) => state.tileDraggable.isTilesDraggable;

export default tileDraggableSlice.reducer;