import { isUserFullNameValid as isTileFullNameValid, isUserEmailValid as isTileEmailValid, isUserPhoneNumberValid as isTilePhoneNumberValid } from "./User";


export const isTileTitleValid = (value: string) => {
	const trimmedValue = value.trim().length;
	return trimmedValue <= 32 && trimmedValue >= 1;
}

export const isTileUrlValid = (value: string) => {
	const regex = /^(https?:\/\/)?([\w\d-]+(\.[\w\d-]+)*\.[a-z]{2,6})([\/\w .%-]*)(\?.*)?$/;
	return regex.test(value);
};

export { isTileEmailValid, isTileFullNameValid, isTilePhoneNumberValid }