import {AxiosError, AxiosInstance} from "axios";
import IProfile from "../schemas/Profile";
import {json} from "react-router-dom";
import IUser from "../schemas/User";
import {IAuthData} from "../schemas/Auth";

export interface AccountInterface {
    getInfo: () => Promise<IUser>;
    uploadPicture: (file: File) => Promise<{detail: string}>;
    deletePicture: () => Promise<{detail: string}>;
}

class AccountService implements AccountInterface {
    baseConfig;

    constructor(private axiosInstance: AxiosInstance) {
        this.baseConfig = {
            headers: {
                "Accept": "application/json"
            },
            withCredentials: true
        }
    }

    async getInfo() {
        try {
            const response = await this.axiosInstance.get<IUser>(
                `/account`,
                this.baseConfig
            );
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.data) {
                if (axiosError.response.status === 401) {
                    // throw axiosError.response.data
                    throw {detail: `Brakiem autoryzacji`}
                    // throw json({detail: `Profil o nazwie ${username} nie istnieje.`}, {status: 404})
                    // throw Error(`Profil o nazwie ${username} nie istnieje.`)
                }
            }
            throw Error("Błąd serwera.")
        }
    }

    async uploadPicture(file: File): Promise<{detail: string}> {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await this.axiosInstance.post(
                `/account/picture`,
                formData,
                this.baseConfig
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            if (!error.response || !(error.response.data instanceof Object)) {
                throw {detail: "Server error"}
            }
            // Możesz dodać więcej obsługi błędów specyficznych dla tego endpointu
            throw {detail: "Server error"}
        }
    }

    async deletePicture(): Promise<{detail: string}> {
        try {
            const response = await this.axiosInstance.delete(
                `/account/picture`,
                this.baseConfig
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            if (!error.response || !(error.response.data instanceof Object)) {
                throw {detail: "Server error"}
            }
            // Możesz dodać więcej obsługi błędów specyficznych dla tego endpointu
            throw {detail: "Server error"}
        }
    }
}

export default AccountService;