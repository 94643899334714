import {AxiosError, AxiosInstance} from "axios";
import IProfile from "../schemas/Profile";
import {json} from "react-router-dom";

export interface ProfileInterface {
    getOneById(userId: string): Promise<IProfile>;
    getOneByUsername(username: string): Promise<IProfile>;
}

class ProfileService implements ProfileInterface {
    config;

    constructor(private axiosInstance: AxiosInstance) {
        this.config = {
            headers: {
                "Accept": "application/json"
            },
            withCredentials: true
        }
    }

    async getOneById<IProfile>(userId: string): Promise<IProfile> {
        return this.axiosInstance.get(`/profiles/${userId}`);
    }

    async getOneByUsername<IProfile>(username: string): Promise<IProfile> {
        try {
            const response = await this.axiosInstance.get(`/profiles/${username}`, this.config);
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.data) {
                if (axiosError.response.status === 404) {
                    // throw axiosError.response.data
                    throw {detail: `Profil o nazwie ${username} nie istnieje.`, status: 404}
                    // throw json({detail: `Profil o nazwie ${username} nie istnieje.`}, {status: 404})
                    // throw Error(`Profil o nazwie ${username} nie istnieje.`)
                }
            }
            throw Error("Błąd serwera.")
        }
    }
}

export default ProfileService;