import React from "react";

interface FromInfoProps {
    children: React.ReactNode;
}

const FormInfo: React.FC<FromInfoProps> = ({children}) => {
    return null;
}

export default FormInfo;