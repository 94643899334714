import React, {useState} from "react";
import {
    defer,
    Form,
    json,
    Link,
    redirect,
    useActionData,
    useLoaderData,
    useLocation,
    useNavigate, useNavigation, useSubmit
} from "react-router-dom";
import useFormInput from "../../hooks/use-form-input";
import classes from "../Auth/AuthForm.module.css";
import FormButton from "../UI/Buttons/FormButton";
import { genderFieldOptions } from "../Auth/RegisterForm";
import {ActionFunction, LoaderFunction} from "@remix-run/router/utils";
import ModalLoading from "../Modals/ModalLoading";
import {AxiosResponse} from "axios";
// import FormSelect from "../UI/Inputs/FormSelect";
import useFormSelect from "../../hooks/use-form-select";
import {
    isUserEmailValid,
    isUserFullNameValid,
    isUserPasswordValid,
    isUserPasswordReplacedValid, isUserUsernameValid, isUserPhoneNumberValidOrEmpty, isUserPhoneNumberValid
} from "../../validators/User";
import FormInput2 from "../UI/Inputs/FormInput2";
import services from "../../services/Services";
import useFormInputNew from "../../hooks/use-form-input-new";
import FormInput from "../../newcomponents/UI/Inputs/FormInput";
import useFormInputAPI from "../../hooks/use-form-input-api";
import FormInputPhone from "../../newcomponents/UI/Inputs/FormInputPhone";
import useFormInputPhone from "../../hooks/use-form-input-phone";
import {isTilePhoneNumberValid} from "../../validators/Tile";
import FormSelect from "../../newcomponents/UI/Inputs/FormInputSelect";
import {IUserCreate, UserGenderEnum} from "../../schemas/User";
import {IRoot} from "../../schemas/Lang/Root";
import {useTranslation} from "react-i18next";

interface UserFormProps {
}

const CreateUserForm: React.FC<UserFormProps> = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const actionData = useActionData() as AxiosResponse<{detail: string}>;
    const isSubmitting = navigation.state === "submitting";
    const submit = useSubmit();

    const { t } = useTranslation();
    const translationForms = t('forms', { returnObjects: true }) as IRoot["forms"];
    const translationPaths = t('paths', { returnObjects: true }) as IRoot["paths"];

    const {
        value: fullNameValue,
        isValid: isValidFullName,
        isActive: isActiveFullName,
        isTouched: isTouchedFullName,
        hasError: hasErrorFullName,
        handleInputChange: handleFullNameChange,
        handleInputBlur: handleFullNameBlur,
        handleInputFocus: handleFullNameFocus,
        reset: resetFullName,
    } = useFormInputNew({
        validateValue: isUserFullNameValid
    })

    const {
        value: usernameValue,
        isValid: isValidUsername,
        isActive: isActiveUsername,
        isTouched: isTouchedUsername,
        isAvailable: isAvailableUsername,
        hasError: hasErrorUsername,
        handleInputChange: handleUsernameChange,
        handleInputBlur: handleUsernameBlur,
        handleInputFocus: handleUsernameFocus,
        reset: resetUsername,
    } = useFormInputAPI({
        validateValue: isUserUsernameValid,
        checkAvailability: async (value: string) => await services.users.isUsernameAvailable(value)
    })

    const {
        value: passwordValue,
        isValid: isValidPassword,
        isActive: isActivePassword,
        isTouched: isTouchedPassword,
        hasError: hasErrorPassword,
        handleInputChange: handlePasswordChange,
        handleInputBlur: handlePasswordBlur,
        handleInputFocus: handlePasswordFocus,
        reset: resetPassword,
    } = useFormInputNew({
        validateValue: isUserPasswordValid
    })

    const {
        value: replacedPasswordValue,
        isValid: isValidReplacedPassword,
        isActive: isActiveReplacedPassword,
        isTouched: isTouchedReplacedPassword,
        hasError: hasErrorReplacedPassword,
        handleInputChange: handleReplacedPasswordChange,
        handleInputBlur: handleReplacedPasswordBlur,
        handleInputFocus: handleReplacedPasswordFocus,
        reset: resetReplacedPassword,
    } = useFormInputNew({
        validateValue: isUserPasswordReplacedValid.bind(null, passwordValue)
    })

    const {
        value: emailValue,
        isValid: isValidEmail,
        isActive: isActiveEmail,
        isTouched: isTouchedEmail,
        isAvailable: isAvailableEmail,
        hasError: hasErrorEmail,
        handleInputChange: handleEmailChange,
        handleInputBlur: handleEmailBlur,
        handleInputFocus: handleEmailFocus,
        reset: resetEmail,
    } = useFormInputAPI({
        validateValue: isUserEmailValid,
        checkAvailability: async (value: string) => await services.users.isEmailAvailable(value)
    })

    const {
        value: genderValue,
        isValid: isValidGender,
        isActive: isActiveGender,
        isTouched: isTouchedGender,
        hasError: hasErrorGender,
        handleSelectChange: handleGenderChange,
        handleSelectBlur: handleGenderBlur,
        handleSelectFocus: handleGenderFocus,
        reset: resetGender,
    } = useFormSelect({
        options: genderFieldOptions
    })

    const {
        value: phoneNumberValue,
        format: phoneNumberFormat,
        ignore: phoneNumberIgnore,
        isTouched: isTouchedPhoneNumber,
        isActive: isActivePhoneNumber,
        isValid: isValidPhoneNumber,
        hasError: hasErrorPhoneNumber,
        handleInputChange: handleInputChangePhoneNumber,
        handleInputBlur: handleInputBlurPhoneNumber,
        handleInputFocus: handleInputFocusPhoneNumber,
        reset: resetPhoneNumber,
    } = useFormInputPhone({
        initialValue: translationForms.users.new.initial.phoneNumberPrefix,
        validateValue: isUserPhoneNumberValidOrEmpty
    })

    const isValidForm = isValidFullName && isValidUsername && isValidPassword && isValidReplacedPassword && isValidEmail && isValidGender && isValidPhoneNumber; // phoneHasError

    const handleSubmitForm: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (!isValidForm) {
            return;
        }

        const dataUser: IUserCreate = {
            fullName: fullNameValue,
            username: usernameValue,
            password: passwordValue,
            email: emailValue,
            gender: genderValue as UserGenderEnum,
        }
        if (isUserPhoneNumberValid(phoneNumberValue, phoneNumberFormat) || phoneNumberIgnore) {
            dataUser.phoneNumber = phoneNumberValue
        }

        const formData = new FormData();
        Object.keys(dataUser).forEach(key => {
            formData.append(key, dataUser[key]);
        });

        submit(formData, {method: "POST", encType: "application/x-www-form-urlencoded"})
    }

    return <>
        {isSubmitting && <ModalLoading/>}
        <Form method="POST" onContextMenu={() => {return "OK"}} onSubmit={handleSubmitForm}>
            <h2 className={classes.header}>{translationForms.users.new.header}</h2>
            <div className={classes["form-elements"]}>
                <FormInput
                    id="full-name"
                    name="full-name"
                    label={translationForms.users.new.labels.fullName}
                    placeholder={translationForms.users.new.labels.fullName}
                    required={true}
                    value={fullNameValue}
                    isActive={isActiveFullName}
                    isTouched={isTouchedFullName}
                    hasError={hasErrorFullName}
                    onChange={handleFullNameChange}
                    onBlur={handleFullNameBlur}
                    onFocus={handleFullNameFocus}
                />
                <FormInput
                    id="username"
                    name="username"
                    label={translationForms.users.new.labels.username}
                    placeholder={translationForms.users.new.labels.username}
                    required={true}
                    value={usernameValue}
                    isActive={isActiveUsername}
                    isTouched={isTouchedUsername}
                    isAvailable={isAvailableUsername}
                    hasError={hasErrorUsername}
                    onChange={handleUsernameChange}
                    onBlur={handleUsernameBlur}
                    onFocus={handleUsernameFocus}
                />
                <FormInput
                    id="password"
                    name="password"
                    label={translationForms.users.new.labels.password}
                    placeholder={translationForms.users.new.labels.password}
                    type="password"
                    required={true}
                    value={passwordValue}
                    isActive={isActivePassword}
                    isTouched={isTouchedPassword}
                    hasError={hasErrorPassword}
                    onChange={handlePasswordChange}
                    onBlur={handlePasswordBlur}
                    onFocus={handlePasswordFocus}
                />
                <FormInput
                    id="password2"
                    name="password2"
                    label={translationForms.users.new.labels.repeatPassword}
                    placeholder={translationForms.users.new.labels.repeatPassword}
                    type="password"
                    required={true}
                    value={replacedPasswordValue}
                    isActive={isActiveReplacedPassword}
                    isTouched={isTouchedReplacedPassword}
                    hasError={hasErrorReplacedPassword}
                    onChange={handleReplacedPasswordChange}
                    onBlur={handleReplacedPasswordBlur}
                    onFocus={handleReplacedPasswordFocus}
                />
                <FormInput
                    id="email"
                    name="email"
                    label={translationForms.users.new.labels.email}
                    placeholder={translationForms.users.new.labels.email}
                    type="text"
                    required={true}
                    value={emailValue}
                    isActive={isActiveEmail}
                    isTouched={isTouchedEmail}
                    isAvailable={isAvailableEmail}
                    hasError={hasErrorEmail}
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                    onFocus={handleEmailFocus}
                    // onValidationStatusChange={(isError) => setEmailValid(!isError)}
                    // onValidate={isUserEmailValid}
                    // checkAvailability={async (value) => await services.users.isEmailAvailable(value)}
                />
                <FormSelect
                    id="plec"
                    name="plec"
                    label={translationForms.users.new.labels.gender.index}
                    required={true}
                    value={genderValue}
                    options={genderFieldOptions}
                    isActive={isActiveGender}
                    isTouched={isTouchedGender}
                    hasError={hasErrorGender}
                    onChange={handleGenderChange}
                    onBlur={handleGenderBlur}
                    onFocus={handleGenderFocus}
                />
                {/*<FormSelect*/}
                {/*    id="gender"*/}
                {/*    name="gender"*/}
                {/*    label="Płeć"*/}
                {/*    required={true}*/}
                {/*    options={genderFieldOptions}*/}
                {/*    onValidationStatusChange={(isError) => setIsGenderValid(!isError)}*/}
                {/*/>*/}
                <FormInputPhone
                    id="phone-number"
                    name="phone-number"
                    value={phoneNumberValue}
                    placeholder={translationForms.users.new.labels.phoneNumber}
                    label={translationForms.users.new.labels.phoneNumber}
                    isActive={isActivePhoneNumber}
                    isTouched={isTouchedPhoneNumber}
                    hasError={hasErrorPhoneNumber}
                    onChange={handleInputChangePhoneNumber}
                    onBlur={handleInputBlurPhoneNumber}
                    onFocus={handleInputFocusPhoneNumber}
                />
                {/*<FormInputPhone id="phone" name="phone" type="tel" label="Nr. Telefonu" />*/}
                <FormButton disabled={!isValidForm && !isSubmitting} type="submit" name="intent" value="user">{translationForms.users.new.submit}</FormButton>
                {actionData && actionData.data.detail && <p>{actionData.data.detail}</p>}
            </div>
        </Form>
    </>;
}

export default CreateUserForm;

export const userFormAction: ActionFunction = async ({request, params}) => {
    return json({username: "Kartaryna"}, {status: 201});
}

export const userFormLoader: LoaderFunction = async ({request}) => {
    return defer({message: "kaplica" })
}