import React from "react";
import {HTMLFormMethod} from "@remix-run/router";
import {AnyTile, AnyTileCreate, AnyTileUpdate, TileTypeEnum} from "../../../schemas/Tile";
import HeaderTileForm from "./HeaderTileForm";
import UrlTileForm from "./UrlTileForm";
import ContactTileForm from "./ContactTileForm";
import IHeaderTile from "../../../schemas/TileExtensions/HeaderTile";
import IContactTile from "../../../schemas/TileExtensions/ContactTile";
import IUrlTile from "../../../schemas/TileExtensions/UrlTile";
import {ActionFunction} from "react-router-dom";

interface TileFormPropsBase {
    method: HTMLFormMethod;
}

interface TileFormPropsWithTile extends TileFormPropsBase {
    tile: AnyTile;
    type?: never; // 'type' jest niedostępne, gdy 'tile' jest podane
}

interface TileFormPropsWithType extends TileFormPropsBase {
    tile?: never; // 'tile' jest niedostępne, gdy 'type' jest podane
    type: TileTypeEnum;
}

export type TileFormProps = TileFormPropsWithTile | TileFormPropsWithType;

const TileForm: React.FC<TileFormProps> = ({
    method,
    tile,
    type
}) => {
    switch (tile ? tile.type : type) {
        case TileTypeEnum.header:
            return <HeaderTileForm method={method} tile={tile as IHeaderTile | undefined}/>;
        case TileTypeEnum.contact:
            return <ContactTileForm method={method} tile={tile as IContactTile | undefined}/>;
        case TileTypeEnum.url:
            return <UrlTileForm method={method} tile={tile as IUrlTile | undefined}/>;
    }
}

export default TileForm;

export const action: ActionFunction = ({ request }) => {
    return null;
}