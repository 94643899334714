import ReactDOM from "react-dom";

import classes from "./ModalOverlay.module.css";
import React, {ReactNode} from "react";

interface ModalOverlayProps {
    onClick?: () => void;
    children: ReactNode;
    className?: string;
}

const ModalOverlay: React.FC<ModalOverlayProps> = ({ onClick, children, className = "" }) => {
    let mouseDownTarget: EventTarget | undefined;

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        mouseDownTarget = e.target;
    }

    const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget && e.target === mouseDownTarget && onClick) {
            onClick();
        }
    }

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        mouseDownTarget = e.target;
    }

    const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
        if (e.target === e.currentTarget && e.target === mouseDownTarget && onClick) {
            onClick();
        }
    }

    const modalClassName = className ? `${classes["modal-overlay"]} ${className}` : classes["modal-overlay"]

    return ReactDOM.createPortal(
        <div
            className={modalClassName}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        >
            {children}
        </div>,
        document.body
    );
};

export default ModalOverlay;