import React, {LinkHTMLAttributes} from "react";
import IContactTile from "../../../schemas/TileExtensions/ContactTile";
import TileContainer from "../Elements/TileContainer";
import TileShareButton from "../Elements/TileShareButton";
import TileIcon from "../Elements/TileIcon";
import TileTitle from "../Elements/TileTitle";

import {generateVCF} from "../../../utils/generateVCF";

import classes from "./ContactTile.module.css";
import {LinkProps} from "react-router-dom";

const ContactTIle: React.FC<IContactTile> = ({
	id,
	iconUrl,
	position,
	userId,
	title,
	active,
	phoneNumber,
	fullName,
	email,
}) => {
	const className = `${classes.container} ${active ? classes.active : classes.disabled}`

	const handleDownloadFile = (event: React.MouseEvent<HTMLAnchorElement>) => {
		console.log("Download event triggered");
		event.preventDefault()
		event.stopPropagation()
		generateVCF({
			fullName: fullName,
			email: email,
			phoneNumber: phoneNumber
		})
	}

	return <TileContainer className={className} active={active}>
		<a
			className={classes.contact}
			onClick={handleDownloadFile}
		>
			<TileIcon iconUrl={iconUrl}/>
			<TileTitle title={title}/>
		</a>
		{/*{active && <TileShareButton tileId={id}/>}*/}
	</TileContainer>
}

export default ContactTIle;