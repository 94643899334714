import React, {useEffect, useState} from "react";
import {Form, Link, useActionData, useNavigation, useSubmit} from "react-router-dom";

import FormButton from "../UI/Buttons/FormButton";
import classes from "./AuthForm.module.css";

import {
    isUserEmailValid,
    isUserFullNameValid,
    isUserPasswordValid,
    isUserPasswordReplacedValid,
    isUserUsernameValid, isUserPhoneNumberValidOrEmpty, isUserPhoneNumberValid
} from "../../validators/User";

import {IUserCreate, UserGenderEnum} from "../../schemas/User";
import services from "../../services/Services";
import FormInput from "../../newcomponents/UI/Inputs/FormInput";
import FormSelect from "../../newcomponents/UI/Inputs/FormInputSelect";
import FormInputPhone from "../../newcomponents/UI/Inputs/FormInputPhone";
import {AxiosResponse} from "axios";
import useFormInputNew from "../../hooks/use-form-input-new";
import useFormInputAPI from "../../hooks/use-form-input-api";
import useFormSelect from "../../hooks/use-form-select";
import useFormInputPhone from "../../hooks/use-form-input-phone";
import {IRoot} from "../../schemas/Lang/Root";
import {useTranslation} from "react-i18next";
import i18nConfig from "../../i18nConfig";

interface RegisterFormProps {
    className?: string
}

export const genderFieldOptions = [
    {value: UserGenderEnum.male, label: i18nConfig.t("forms.signup.labels.gender.male") as string},
    {value: UserGenderEnum.female, label: i18nConfig.t("forms.signup.labels.gender.female") as string},
    {value: UserGenderEnum.other, label: i18nConfig.t("forms.signup.labels.gender.other") as string}
]


const RegisterForm: React.FC<RegisterFormProps> = ({className}) => {
    const navigation = useNavigation();
    const submit = useSubmit();
    const actionData = useActionData() as AxiosResponse<{detail: string}>;
    const isSubmitting = navigation.state === "submitting";
    const { t } = useTranslation();
    const translationForms = t('forms', { returnObjects: true }) as IRoot["forms"];
    const translationPaths = t('paths', { returnObjects: true }) as IRoot["paths"];

    const {
        value: fullNameValue,
        isValid: isValidFullName,
        isActive: isActiveFullName,
        isTouched: isTouchedFullName,
        hasError: hasErrorFullName,
        handleInputChange: handleFullNameChange,
        handleInputBlur: handleFullNameBlur,
        handleInputFocus: handleFullNameFocus,
        reset: resetFullName,
    } = useFormInputNew({
        validateValue: isUserFullNameValid
    })

    const {
        value: usernameValue,
        isValid: isValidUsername,
        isActive: isActiveUsername,
        isTouched: isTouchedUsername,
        isAvailable: isAvailableUsername,
        hasError: hasErrorUsername,
        handleInputChange: handleUsernameChange,
        handleInputBlur: handleUsernameBlur,
        handleInputFocus: handleUsernameFocus,
        reset: resetUsername,
    } = useFormInputAPI({
        validateValue: isUserUsernameValid,
        checkAvailability: async (value: string) => await services.users.isUsernameAvailable(value)
    })

    const {
        value: passwordValue,
        isValid: isValidPassword,
        isActive: isActivePassword,
        isTouched: isTouchedPassword,
        hasError: hasErrorPassword,
        handleInputChange: handlePasswordChange,
        handleInputBlur: handlePasswordBlur,
        handleInputFocus: handlePasswordFocus,
        reset: resetPassword,
    } = useFormInputNew({
        validateValue: isUserPasswordValid
    })

    const {
        value: replacedPasswordValue,
        isValid: isValidReplacedPassword,
        isActive: isActiveReplacedPassword,
        isTouched: isTouchedReplacedPassword,
        hasError: hasErrorReplacedPassword,
        handleInputChange: handleReplacedPasswordChange,
        handleInputBlur: handleReplacedPasswordBlur,
        handleInputFocus: handleReplacedPasswordFocus,
        reset: resetReplacedPassword,
    } = useFormInputNew({
        validateValue: isUserPasswordReplacedValid.bind(null, passwordValue)
    })

    const {
        value: emailValue,
        isValid: isValidEmail,
        isActive: isActiveEmail,
        isTouched: isTouchedEmail,
        isAvailable: isAvailableEmail,
        hasError: hasErrorEmail,
        handleInputChange: handleEmailChange,
        handleInputBlur: handleEmailBlur,
        handleInputFocus: handleEmailFocus,
        reset: resetEmail,
    } = useFormInputAPI({
        validateValue: isUserEmailValid,
        checkAvailability: async (value: string) => await services.users.isEmailAvailable(value)
    })

    const {
        value: genderValue,
        isValid: isValidGender,
        isActive: isActiveGender,
        isTouched: isTouchedGender,
        hasError: hasErrorGender,
        handleSelectChange: handleGenderChange,
        handleSelectBlur: handleGenderBlur,
        handleSelectFocus: handleGenderFocus,
        reset: resetGender,
    } = useFormSelect({
        options: genderFieldOptions
    })

    const {
        value: phoneNumberValue,
        format: phoneNumberFormat,
        isTouched: isTouchedPhoneNumber,
        isActive: isActivePhoneNumber,
        isValid: isValidPhoneNumber,
        hasError: hasErrorPhoneNumber,
        handleInputChange: handleInputChangePhoneNumber,
        handleInputBlur: handleInputBlurPhoneNumber,
        handleInputFocus: handleInputFocusPhoneNumber,
        reset: resetPhoneNumber,
    } = useFormInputPhone({
        initialValue: translationForms.signup.initial.phoneNumberPrefix,
        validateValue: isUserPhoneNumberValidOrEmpty
    })

    const isValidForm = isValidFullName && isValidUsername && isValidPassword && isValidReplacedPassword && isValidEmail && isValidGender && isValidPhoneNumber; // phoneHasError

    const handleSubmitForm: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (!isValidForm) {
            return;
        }

        const dataUser: IUserCreate = {
            fullName: fullNameValue,
            username: usernameValue,
            password: passwordValue,
            email: emailValue,
            gender: genderValue as UserGenderEnum,
        }
        if (isUserPhoneNumberValid(phoneNumberValue, phoneNumberFormat)) {
            dataUser.phoneNumber = phoneNumberValue
        }

        const formData = new FormData();
        Object.keys(dataUser).forEach(key => {
            formData.append(key, dataUser[key]);
        });

        submit(formData, {method: "POST", encType: "application/x-www-form-urlencoded"})
    }


    return <Form method="POST" className={className} onSubmit={handleSubmitForm}>
        <h2 className={classes.header}>{translationForms.signup.header}</h2>
        <div className={classes["form-elements"]}>
            <FormInput
                id="full-name"
                name="full-name"
                label={translationForms.signup.labels.fullName}
                placeholder={translationForms.signup.labels.fullName}
                required={true}
                value={fullNameValue}
                isActive={isActiveFullName}
                isTouched={isTouchedFullName}
                hasError={hasErrorFullName}
                onChange={handleFullNameChange}
                onBlur={handleFullNameBlur}
                onFocus={handleFullNameFocus}
            />
            <FormInput
                id="username"
                name="username"
                label={translationForms.signup.labels.username}
                placeholder={translationForms.signup.labels.username}
                required={true}
                value={usernameValue}
                isActive={isActiveUsername}
                isTouched={isTouchedUsername}
                isAvailable={isAvailableUsername}
                hasError={hasErrorUsername}
                onChange={handleUsernameChange}
                onBlur={handleUsernameBlur}
                onFocus={handleUsernameFocus}
            />
            <FormInput
                id="password"
                name="password"
                label={translationForms.signup.labels.password}
                placeholder={translationForms.signup.labels.password}
                type="password"
                required={true}
                value={passwordValue}
                isActive={isActivePassword}
                isTouched={isTouchedPassword}
                hasError={hasErrorPassword}
                onChange={handlePasswordChange}
                onBlur={handlePasswordBlur}
                onFocus={handlePasswordFocus}
            />
            <FormInput
                id="password2"
                name="password2"
                label={translationForms.signup.labels.repeatPassword}
                placeholder={translationForms.signup.labels.repeatPassword}
                type="password"
                required={true}
                value={replacedPasswordValue}
                isActive={isActiveReplacedPassword}
                isTouched={isTouchedReplacedPassword}
                hasError={hasErrorReplacedPassword}
                onChange={handleReplacedPasswordChange}
                onBlur={handleReplacedPasswordBlur}
                onFocus={handleReplacedPasswordFocus}
            />
            <FormInput
                id="email"
                name="email"
                label={translationForms.signup.labels.email}
                placeholder={translationForms.signup.labels.email}
                type="text"
                required={true}
                value={emailValue}
                isActive={isActiveEmail}
                isTouched={isTouchedEmail}
                isAvailable={isAvailableEmail}
                hasError={hasErrorEmail}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                onFocus={handleEmailFocus}
            />
            <FormSelect
                id="plec"
                name="plec"
                label={translationForms.signup.labels.gender.index}
                required={true}
                value={genderValue}
                options={genderFieldOptions}
                isActive={isActiveGender}
                isTouched={isTouchedGender}
                hasError={hasErrorGender}
                onChange={handleGenderChange}
                onBlur={handleGenderBlur}
                onFocus={handleGenderFocus}
            />
            <FormInputPhone
                id="phone-number"
                name="phone-number"
                value={phoneNumberValue}
                placeholder={translationForms.signup.labels.phoneNumber}
                label={translationForms.signup.labels.phoneNumber}
                isActive={isActivePhoneNumber}
                isTouched={isTouchedPhoneNumber}
                hasError={hasErrorPhoneNumber}
                onChange={handleInputChangePhoneNumber}
                onBlur={handleInputBlurPhoneNumber}
                onFocus={handleInputFocusPhoneNumber}
            />
            <FormButton type="submit" disabled={!isValidForm}>{translationForms.signup.submit}</FormButton>
            <p className={classes["switch-mode"]}>{translationForms.signup.paragraphs.alreadyHasAccount} <Link to={`/${translationPaths.login.index}`}>{translationForms.signup.paragraphs.login}</Link></p>
        </div>
    </Form>;
}

export default RegisterForm;




