import React, {useEffect, useState, useTransition} from 'react';
import './App.css';
import {
    createBrowserRouter,
    createRoutesFromElements, Outlet, redirect,
    Route,
    RouteObject,
    RouterProvider,
    Routes,
} from 'react-router-dom';
import RootLayout from "./pages/Root/Root";
import {Router as RemixRouter} from "@remix-run/router/dist/router";
import ErrorPage from "./pages/Root/Error";
import ProfilePage, {loader as userProfileLoader} from "./pages/Profile/Profile";
import {Provider, useDispatch, useSelector} from "react-redux";
import store, {AppDispatch} from "./store/store";
import ShopPage from "./pages/Root/Shop";
import UserNotFoundPage from "./pages/Profile/ProfileError";
import AuthPage, {action as AuthPageAction} from "./pages/Root/Auth";
import WelcomePage from "./pages/Root/Welcome";
import NewUserPage, {action as newUserAction} from "./pages/Dashboard/Users/NewUser";
import UserProfileEditPage from "./pages/Profile/UserProfileEdit";
import LoginPage, {action as loginAction} from "./pages/Auth/Login";
import RegisterPage, {action as registerAction} from "./pages/Auth/Register";
import ResetPasswordPage from "./pages/Auth/ResetPassword";
import {useAppDispatch, useAppSelector} from "./hooks/hooks";
import NewTileFormModal from "./components/Tiles/NewTileFormModal";
import HeaderTileForm from "./components/Tiles/Forms/HeaderTileForm";
import PhoneTileForm from "./components/Tiles/Forms/PhoneTileForm";
import UrlTileForm from "./components/Tiles/Forms/UrlTileForm";
// import TileActionsModal, { action as tileAction } from "./components/Tiles/TileActionsModal";
import EditTile from "./components/Tiles/EditTile";
import {selectUser} from "./store/slices/userSlice";
import TilePage, {loader as tilePageLoader} from "./pages/Profile/Tile/Tile";
import EditTilePage, {action as tileAction, loader as tileLoader} from "./pages/Profile/Tile/EditTile";
import NewTilePage, {action as newTileAction} from "./pages/Profile/Tile/NewTile";
import {selectAuth} from "./store/slices/authSlice";
import services from "./services/Services";
import {setUserAction} from "./store/actions/userActions";
import {clearAuthAction} from "./store/actions/authActions";
import ModalLoading from "./components/Modals/ModalLoading";
import ProfileErrorPage from "./pages/Profile/ProfileError";
import HomePage from "./pages/Root/Home";
import NotFoundPage from "./pages/NotFound";
import LogoutPage, {action as logoutAction} from "./pages/Auth/Logout";
import AuthRequired from "./newcomponents/AuthRequired/AuthRequired";
import {UserRoleEnum} from "./schemas/User";
import PicturePage, {action as pictureAction} from "./pages/Profile/Picture/Picture";
import RequiredVerificationPage from "./pages/Profile/RequiredVerification";
import VerifyPage, {action as verifyAction, loader as verifyLoader} from "./pages/Auth/Verify";
import i18n from "./i18nConfig";
import {useTranslation} from "react-i18next";
import {IPaths} from "./schemas/Lang/Paths";
import {IRoot} from "./schemas/Lang/Root";
import {CURRENT_VERSION} from "./version";

const domain = window.location.hostname;

if (domain.includes('pl')) {
    i18n.changeLanguage('pl');
} else if (domain.includes('qa')) {
    i18n.changeLanguage('en');
}

const RootLoader: React.FC = () => {
    return <div className={"Root-loader"}>
        <ModalLoading/>
    </div>
}

const Index: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        const savedVersion = localStorage.getItem('app_version');
        if (savedVersion !== CURRENT_VERSION) {
            localStorage.setItem('app_version', CURRENT_VERSION);
            if (savedVersion !== null) {
                console.log("Zaktualizowano wersję aplikacji. Odświeżanie strony...");
                window.location.reload();
            }
        }
    }, []);

    const paths = t('paths', { returnObjects: true }) as IRoot["paths"];
    const routerDefinitions: RouteObject[] = createRoutesFromElements([
            <Route
                path="/"
                id="root"
                element={<RootLayout/>}
                errorElement={<ErrorPage/>}
            >
                <Route path={`${paths.profile.index}/:username`} id="profile" element={<ProfilePage/>} loader={userProfileLoader} errorElement={<ProfileErrorPage/>}>
                    <Route path={paths.profile.tiles.index} element={<TilePage/>} loader={tilePageLoader}>
                        <Route path={paths.profile.tiles.new.index} element={<NewTilePage/>} action={newTileAction}/>
                        <Route path=":tileId" element={<EditTilePage/>} loader={tileLoader} action={tileAction}/>
                    </Route>
                    <Route path={paths.profile.photo.index} element={<PicturePage/>} action={pictureAction}/>
                    <Route path={`${paths.profile.verification.index}/*`} element={<VerifyPage/>} action={verifyAction} loader={verifyLoader}/>
                </Route>
                {/*<Route path="profil" element={<ProfilePage/>}/>*/}
                {/*<Route path="profil/:username" id="user-profile" element={<ProfilePage/>} loader={userProfileLoader} errorElement={<UserNotFoundPage/>}/>*/}
                {/*<Route path="auth" element={<AuthPage />} action={AuthPageAction}/>*/}
                <Route path={paths.login.index} element={<LoginPage/>} action={loginAction(dispatch)}/>
                <Route path={paths.signup.index} element={<RegisterPage/>} action={registerAction(dispatch)}/>
                <Route path="resetowanie-hasla" element={<ResetPasswordPage/>}/>
                <Route path="powitanie" element={<WelcomePage/>}/>
                <Route path={paths.logout.index} element={<LogoutPage/>} action={logoutAction(dispatch)}/>
            </Route>,
            <Route path={paths.dashboard.index}>
                <Route path={paths.dashboard.users.index}>
                    <Route path={paths.dashboard.users.new.index} element={<NewUserPage/>} action={newUserAction}/>
                </Route>
            </Route>,
            <Route path="*" element={<NotFoundPage/>}/>
        ]
    );

    const router: RemixRouter = createBrowserRouter(routerDefinitions);
    return <RouterProvider router={router} fallbackElement={<RootLoader/>}/>
}


const App: React.FC = () => {

    return <Provider store={store}>
        {/*<RouterProvider router={router} />*/}
        <Index/>
    </Provider>;
}

export default App;
