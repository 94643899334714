import React from "react";

import classes from "./ModalFragmentHeader.module.css"
import SVGButton from "../UI/Buttons/SVGButton";
import SVGClose from "../Icons/Close";
import SVGReturn from "../Icons/Return";
interface ModalFragmentHeaderProps {
    onClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onReturn?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode;
    className?: string;
}

const ModalFragmentHeader: React.FC<ModalFragmentHeaderProps> = ({
    onReturn,
    onClose,
    children,
    className=""
}) => {
    const containerClassName = className ? `${classes.container} ${className}` : classes.container;

    return <div className={containerClassName}>
        <div className={classes.above}>
            <h3 className={classes.header}>{children}</h3>
        </div>
        <div className={classes.button}>
            {onClose &&
                <SVGButton onClick={onClose}>
                    <SVGClose/>
                </SVGButton>
            }
            {onReturn &&
                <SVGButton onClick={onReturn}>
                    <SVGReturn/>
                </SVGButton>
            }
        </div>
    </div>;
}

export default ModalFragmentHeader;