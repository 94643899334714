import {AxiosError, AxiosInstance, AxiosResponse} from "axios";
import {json} from "react-router-dom";
import IUser, {IUserCreate} from "../schemas/User";
import {IAvailability} from "../schemas/common";

export interface UserFilterProps {
    username: string;
}

interface UserMultiProps {
    page: number;
    pageSize: number;
    orderBy: "asc" | "desc";
    filter: UserFilterProps;
}

// interface UserMultiRequest {
//     (args: UserMultiProps): Promise<UserData[]>
// }

export interface UserServiceInterface {
    getOneById(userId: string): Promise<IUser>;
    getOneByUsername(username: string): Promise<IUser>;
    getMulti(params: UserMultiProps): Promise<IUser[]>;
    // create(userData: UserCreateData): Promise<UserResponseData | Response>;
    create(userData: FormData): Promise<AxiosResponse<IUser>>;
    // create(userData: UserCreateData): Promise<Response>;
    // uploadPicture(userId: string, image: File): Promise<IUser | Response>;
    uploadPicture(userId: string, image: File): Promise<IUser | Response>;
    isUsernameAvailable(username: string): Promise<IAvailability>;
    isEmailAvailable(username: string): Promise<IAvailability>;
}

class UserService implements UserServiceInterface {
    constructor(private axiosInstance: AxiosInstance) {}

    public async getOneById(userId: string): Promise<IUser> {
        return this.axiosInstance.get(`/users/${userId}`)
    }
    public async getOneByUsername(username: string): Promise<IUser> {
        return this.axiosInstance.get("/users", {params: {username: username}})
    }
    public async getMulti(params: UserMultiProps): Promise<IUser[]> {
        return this.axiosInstance.get(
            "/users",
            {
                params: {
                    page: params.page,
                    pageSize: params.pageSize,
                    orderBy: params.orderBy,
                    filter: params.filter
                }
            }
        )
    }
    public async create(userData: FormData): Promise<AxiosResponse<IUser>> {
        const dataObject = Object.fromEntries(userData.entries());
        return await this.axiosInstance.post(
            '/users',
            userData,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    // "Accept": "application/json",
                },
                withCredentials: true
            }
        )
        // try {
        //     return await this.axiosInstance.post(
        //         '/users',
        //         {...userData},
        //         {
        //             headers: {
        //                 "Content-Type": "application/json",
        //                 "Accept": "application/json",
        //             }
        //         }
        //     )
        // } catch (err) {
        //     const error = err as AxiosError;
        //     if (!error.response || !(error.response.data instanceof Object)) {
        //         throw json({detail: "Server error"}, {status: 500})
        //     }
        //     if (error.response.status === 400) {
        //         throw json({...error.response.data}, {status: 400});
        //     }
        //     if (error.response.status === 422) {
        //         throw json({detail: "Missing username/e-mail or password"}, { status: 422 });
        //     }
        //     throw json({detail: "Unexpected error occurred"}, {status: error.response.status})
        // }
    }
    public async uploadPicture(userId: string, file: File): Promise<IUser | Response> {
        const config = {
            headers: {
                "Accept": "application/json"
            },
            withCredentials: true
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('userId', userId);

        try {
            const response = await this.axiosInstance.post(
                `/users/picture`,
                formData,
                config
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            if (!error.response || !(error.response.data instanceof Object)) {
                throw {detail: "Server error"}
            }
            // Możesz dodać więcej obsługi błędów specyficznych dla tego endpointu
            return json({detail: "Unexpected error occurred"}, {status: error.response.status});
        }
    }

    async deletePicture(userId: string): Promise<IUser | Response> {
        const config = {
            headers: {
                "Accept": "application/json"
            },
            withCredentials: true
        }

        try {
            const response = await this.axiosInstance.delete(
                `/users/picture/${userId}`,
                config
            );
            return response.data;
        } catch (err) {
            const error = err as AxiosError;
            if (!error.response || !(error.response.data instanceof Object)) {
                throw {detail: "Server error"}
            }
            // Możesz dodać więcej obsługi błędów specyficznych dla tego endpointu
            return json({detail: "Unexpected error occurred"}, {status: error.response.status});
        }
    }

    async isUsernameAvailable(username: string): Promise<IAvailability>{
        const config = {
            headers: {
                "Accept": "application/json"
            },
            withCredentials: true
        }
        const url = new URL(this.axiosInstance.defaults.baseURL + "/users/username/attempt");
        url.searchParams.set("username", username)

        try {
            const result = await this.axiosInstance.get(url.toString(), config)
            return result.data;
        }
        catch (err) {
            const axiosError = err as AxiosError;
            if (axiosError.response?.data) {
                throw json({...axiosError.response.data}, {status: axiosError.response.status})
            }

            throw json({detail: "Something went wrong."}, {status: 500})
        }
    }

    async isEmailAvailable(email: string): Promise<IAvailability>{
        const config = {
            headers: {
                "Accept": "application/json"
            },
            withCredentials: true
        }

        const url = new URL(this.axiosInstance.defaults.baseURL + "/users/email/attempt");
        url.searchParams.set("email", email)

        try {
            const result = await this.axiosInstance.get(url.toString(), config)
            return result.data;
        }
        catch (err) {
            const axiosError = err as AxiosError;
            if (axiosError.response?.data) {
                throw json({...axiosError.response.data}, {status: axiosError.response.status})
            }

            throw json({detail: "Something went wrong."}, {status: 500})
        }
    }
}

export default UserService;