import React from "react";
import {AnyTile, TileTypeEnum} from "../../schemas/Tile";
import HeaderTile from "./Types/HeaderTile";
import ContactTIle from "./Types/ContactTIle";
import UrlTile from "./Types/UrlTile";

const Tile: React.FC<AnyTile> = (tile) => {
    switch (tile.type) {
        case TileTypeEnum.header:
            return <HeaderTile {...tile}/>;
        case TileTypeEnum.contact:
            return <ContactTIle {...tile}/>;
        case TileTypeEnum.url:
            return <UrlTile {...tile}/>;
    }
}

export default Tile;