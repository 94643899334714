import React from "react";

import classes from "./NotFound.module.css";

const NotFoundPage: React.FC = () => {
    return <div className={classes.container}>
        <h3>
            Wyszukiwana strona nie istnieje.
        </h3>
    </div>
}

export default NotFoundPage;