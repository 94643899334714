import React, {ReactNode} from "react";
import classes from "./ModalOption.module.css";

export interface ModalOptionProps {
	children: ReactNode;
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	className?: string
}

const ModalOption: React.FC<ModalOptionProps> = ({
	children,
	onClick,
	className
}) => {
	const optionClassName: string = className ? `${classes.option} ${className}` : classes.option;

	return <button className={optionClassName} onClick={onClick}>{children}</button>;
}

export default ModalOption;