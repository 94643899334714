import {AxiosInstance} from "axios";
import {AnyTile, AnyTileCreate, AnyTileUpdate} from "../schemas/Tile";
import {AxiosError} from "axios/index";
import {json} from "react-router-dom";

export interface ITileService {
	getOneById(tileId: string): Promise<AnyTile>;
	getIconByIconUrl(iconUrl: string): Promise<string>;
	create(data: AnyTileCreate): Promise<AnyTile>;
	createByAdmin(userId: string, data: AnyTileCreate): Promise<AnyTile>;
	update(tileId: string, data: AnyTileUpdate): Promise<AnyTile>;
	updateForUser(userId: string, tileId: string, data: AnyTileUpdate): Promise<AnyTile>;
	setPosition(tileId: string, position: number): Promise<AnyTile>;
	setActive(tileId: string, active: boolean): Promise<AnyTile>;
	delete(tileId: string): Promise<{detail: string}>;
}

class TileService implements ITileService {
	config;

	constructor(private axiosInstance: AxiosInstance) {
		this.config = {
			headers: {
				"Accept": "application/json"
			},
			withCredentials: true
		}
	}

	async getOneById(tileId: string): Promise<AnyTile> {
		try {
			const result = await this.axiosInstance.get(`/tiles/${tileId}`, this.config)
			return result.data;
		}
		catch (err) {
			const axiosError = err as AxiosError;
			if (axiosError.response?.data) {
				throw json({...axiosError.response.data}, {status: axiosError.response.status})
			}

			throw json({detail: "Something went wrong."}, {status: 500})
		}
	}

	async getIconByIconUrl(iconUrl: string): Promise<string> {
		const result = await this.axiosInstance.get(iconUrl);
		return result.data;
	}

	async create<T extends AnyTileCreate>(data: AnyTileCreate): Promise<T> {
		const config = {
			headers: {
				"Accept": "application/json"
			},
			withCredentials: true
		}

		try {
			const result = await this.axiosInstance.post("/tiles", data, config)
			return result.data;
		}
		catch (err) {
			const axiosError = err as AxiosError;
			if (axiosError.response?.data) {
				throw json({...axiosError.response.data}, {status: axiosError.response.status})
			}

			throw json({detail: "Something went wrong."}, {status: 500})
		}
	}

	async createByAdmin<T extends AnyTileCreate>(userId: string, data: AnyTileCreate): Promise<T> {
		const config = {
			headers: {
				"Accept": "application/json"
			},
			withCredentials: true
		}

		if (data.userId) {
			data.userId = undefined
		}

		try {
			const result = await this.axiosInstance.post(`/tiles/${userId}`, data, config)
			return result.data;
		}
		catch (err) {
			const axiosError = err as AxiosError;
			if (axiosError.response?.data) {
				throw json({...axiosError.response.data}, {status: axiosError.response.status})
			}

			throw json({detail: "Something went wrong."}, {status: 500})
		}
	}

	async update<T extends AnyTile>(tileId: string, data: AnyTileUpdate): Promise<T> {
		const config = {
			headers: {
				"Accept": "application/json"
			},
			withCredentials: true
		}

		try {
			const result = await this.axiosInstance.patch(`/tiles/${tileId}`, data, config)
			return result.data
		}
		catch (err) {
			const axiosError = err as AxiosError;
			if (axiosError.response?.data) {
				throw json({...axiosError.response.data}, {status: axiosError.response.status})
			}

			throw json({detail: "Something went wrong."}, {status: 500})
		}
	}

	async updateForUser<T extends AnyTile>(userId: string, tileId: string, data: AnyTileUpdate): Promise<T> {
		const config = {
			headers: {
				"Accept": "application/json"
			},
			withCredentials: true
		}

		if (data.userId) {
			data.userId = undefined
		}

		try {
			const result = await this.axiosInstance.patch(`/tiles/${userId}/${tileId}`, data, config)
			return result.data
		}
		catch (err) {
			const axiosError = err as AxiosError;
			if (axiosError.response?.data) {
				throw json({...axiosError.response.data}, {status: axiosError.response.status})
			}

			throw json({detail: "Something went wrong."}, {status: 500})
		}
	}

	async setPosition<T extends AnyTile>(tileId: string, position: number): Promise<T> {
		const data = {position: position} as AnyTileUpdate;
		return await this.update(tileId, data)
	}

	async setActive<T extends AnyTile>(tileId: string, active: boolean): Promise<T> {
		const data = {active: active} as AnyTileUpdate;
		return await this.update(tileId, data)
	}

	async delete(tileId: string): Promise<{detail: string}> {
		const config = {
			headers: {
				"Accept": "application/json"
			},
			withCredentials: true
		}

		try {
			const result = await this.axiosInstance.delete(`/tiles/${tileId}`, config)
			return result.data
		}
		catch (err) {
			const axiosError = err as AxiosError;
			if (axiosError.response?.data) {
				throw json({...axiosError.response.data}, {status: axiosError.response.status})
			}

			throw json({detail: "Something went wrong."}, {status: 500})
		}
	}
}

export default TileService;