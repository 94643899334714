import React, {HTMLInputTypeAttribute, useState} from 'react';

import classes from "./FormSelect.module.css";
import SVGArrow from "../../../components/Icons/Arrow";

interface FormSelectProps {
    id?: string | undefined;
    name?: string | undefined;
    type?: React.HTMLInputTypeAttribute | undefined;
    value: string;
    required?: boolean | undefined;
    label?: string | undefined;
    options: { value: string; label: string }[];
    isActive?: boolean | undefined;
    isTouched?: boolean | undefined;
    hasError?: boolean | undefined;
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLSelectElement, Element>) => void | undefined;
    onBlur?: (event: React.FocusEvent<HTMLSelectElement, Element>) => void | undefined;
}

const FormSelect: React.FC<FormSelectProps> = ({
    id,
    name,
    value,
    required = false,
    label,
    options,
    isActive,
    isTouched,
    hasError,
    onChange,
    onFocus,
    onBlur,
}) => {
    const [wasVibrated, setWasVibrated] = useState(false);
    const isVibrationSupported = 'vibrate' in navigator;

    let errorMessage = ""

    if (required && value.trim() === "" && isTouched) {
        errorMessage = "wymagane pole"
    } else if (hasError) {
        errorMessage = "nie poprawna wartość"
    }

    let fieldClassName = classes.field;

    if (isActive || value) {
        fieldClassName += ` ${classes.active}`;
    }

    if (errorMessage) {
        fieldClassName += ` ${classes.error}`;
        if (!wasVibrated) {
            if (isVibrationSupported) {
                navigator.vibrate(200);
            }
            setWasVibrated(true);
        }
    }

    return (
        <div className={fieldClassName}>
            <select
                id={id}
                name={name}
                value={value}
                required={required}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                placeholder={label}
            >
                {/*<option className={classes["placeholder-option"]} value="">{label}</option>*/}
                <option className={classes["placeholder-option"]} value="" disabled hidden>{label}</option>
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <label htmlFor={id} className={errorMessage ? classes.error : undefined}>
                {required ? "*" : ""}{label}{errorMessage ? " - " + errorMessage : ""}
            </label>
            <span className={classes.arrow}>
				<SVGArrow/>
			</span>
        </div>
    );
};

export default FormSelect;

export const isCorrectDropdownOption = (value: string, options: Array<{ value: string; label: string }>): boolean => {
    return options.some(option => option.value === value);
}
