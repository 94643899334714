import React, {useReducer} from "react";

export interface UseFormSelectProps {
	initialValue?: string;
	options: Array<{ value: string; label: string }>
}

export interface UseFormSelectInterface {
	(props: UseFormSelectProps): {
		value: string;
		isTouched: boolean;
		isActive: boolean;
		isValid: boolean;
		hasError: boolean;
		handleSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
		handleSelectBlur: (event: React.FocusEvent<HTMLSelectElement>) => void;
		handleSelectFocus: (event: React.FocusEvent<HTMLSelectElement>) => void;
		reset: () => void;
	};
}

interface InitialStateInterface {
	value: string;
	isTouched: boolean;
	isActive: boolean;
}


const initialState: InitialStateInterface = {
	value: "",
	isTouched: false,
	isActive: false,
}

interface ActionStateInterface extends Partial<InitialStateInterface> {
	type: string;
}

const inputStateReducer = (state: InitialStateInterface, action: ActionStateInterface): InitialStateInterface => {
	switch (action.type) {
		case "CHANGE":
			return {...state, value: action.value || "", isActive: true};
		case "BLUR":
			return {...state, isTouched: true, isActive: false}
		case "FOCUS":
			return {...state, isActive: true}
		case "RESET":
			return {...initialState}
		default:
			return {...state}
	}
}

const useFormSelect: UseFormSelectInterface = ({
	initialValue = "",
	options
}) => {
	const [inputState, dispatch] = useReducer(inputStateReducer, {...initialState, value: initialValue});

	let valueIsValid = isCorrectDropdownOption(inputState.value, options);

	const hasError = !valueIsValid && inputState.isTouched;

	const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		dispatch({type: "CHANGE", value: event.target.value})
	}

	const handleSelectBlur = (event: React.FocusEvent<HTMLSelectElement>) => {
		dispatch({type: "BLUR"})
	}

	const handleSelectFocus = (event: React.FocusEvent<HTMLSelectElement>) => {
		dispatch({type: "FOCUS"})
	}

	const reset = () => {
		dispatch({type: "RESET"})
	}

	return {
		value: inputState.value,
		isTouched: inputState.isTouched,
		isActive: inputState.isActive,
		isValid: valueIsValid,
		hasError,
		handleSelectChange,
		handleSelectBlur,
		handleSelectFocus,
		reset,
	}
}

export default useFormSelect;

export const isCorrectDropdownOption = (value: string, options: Array<{ value: string; label: string }>): boolean => {
	return options.some(option => option.value === value);
}
