import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import UserService from "./UserService";
import ProfileService from "./ProfileService";
import AuthService from "./AuthService";
import {AxiosError} from "axios/index";
import {json} from "react-router-dom";
import TileService from "./TileService";
import AccountService from "./AccountService";


class Services {
    public readonly users: UserService;
    public readonly profiles: ProfileService;
    public readonly auth: AuthService;
    public readonly tiles: TileService;
    public readonly account: AccountService;

    public constructor(private axiosInstance: AxiosInstance) {
        this.users = new UserService(axiosInstance);
        this.profiles = new ProfileService(axiosInstance);
        this.auth = new AuthService(axiosInstance);
        this.tiles = new TileService(axiosInstance);
        this.account = new AccountService(axiosInstance);
    }

    // async get(url: string, args?: AxiosRequestConfig): Promise<any> {
    //     try {
    //         return this.axiosInstance.get(url, {...args})
    //     } catch (err) {
    //         const error = err as AxiosError;
    //         if (!error.response || !(error.response.data instanceof Object)) {
    //             throw json({detail: "Server error"}, {status: 500});
    //         }
    //         throw error
    //     }
    // }
}

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        withCredentials: true
    }
})

// axiosInstance.interceptors.response.use(
//     response => response,
//     async err => {
//         const axiosError = err as AxiosError;
//         if (axiosError.response?.data) {
//             console.log({...axiosError.response.data})
//             // return Promise.reject(json({...axiosError.response.data}, {status: axiosError.response.status}));
//             throw json({...axiosError.response.data}, {status: axiosError.response.status});
//         }
//         return Promise.reject(json({detail: "Server error"}, {status: 500}))
//         // if (!error.response || !(error.response.data instanceof Object)) {
//         //     return Promise.reject(json({detail: "Server error"}, {status: 500}))
//         // }
//         // // return Promise.reject(error);
//         // console.log("axiosInstance use:", error.response.status)
//         // return Promise.reject(json({...error.response.data}, {status: error.response.status}));
//         // return json({...error.response.data}, {status: error.status});
//     },
// )

const services = new Services(axiosInstance);

export default services;