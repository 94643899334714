import {clearAuth, setIsUserAuthenticated} from "../slices/authSlice";

export const setAuthAction = (isUserAuthenticated: boolean) => {
    localStorage.setItem("isUserAuthenticated", JSON.stringify(isUserAuthenticated))
    return setIsUserAuthenticated({isUserAuthenticated})
}

export const clearAuthAction = () => {
    localStorage.removeItem("isUserAuthenticated")
    return clearAuth()
}