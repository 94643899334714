import {saveAs} from "file-saver";

export interface IFileVCF {
	fullName: string
	email: string,
	phoneNumber: string
}

export const generateVCF = ({fullName, email, phoneNumber}: IFileVCF) => {
	const vcfContent = `
BEGIN:VCARD
VERSION:3.0
FN:${fullName}
TEL;TYPE=WORK,VOICE:${phoneNumber}
EMAIL;TYPE=PREF,INTERNET:${email}
END:VCARD
`;

	// Utworzenie obiektu Blob z zawartością VCF
	const blob = new Blob([vcfContent], { type: 'text/vcard' });

	// Użycie FileSaver do pobrania pliku
	saveAs(blob, `${fullName}.vcf`);

	// // Utworzenie obiektu Blob z zawartością VCF
	// const blob = new Blob([vcfContent], { type: 'text/vcard' });
	//
	// // Utworzenie URL do obiektu Blob
	// const blobUrl = window.URL.createObjectURL(blob);
	//
	// // Inicjowanie pobierania pliku
	// window.open(blobUrl); // Możesz również użyć location.href = blobUrl;
	//
	// // Opóźnienie i zwolnienie URL Blob
	// setTimeout(() => {
	// 	window.URL.revokeObjectURL(blobUrl);
	// }, 100); // Opóźnienie 100 ms

	// // Utworzenie obiektu Blob z zawartością VCF
	// const blob = new Blob([vcfContent], { type: 'text/vcard' });
	//
	// // Utworzenie linku do pobrania pliku
	// const downloadLink = document.createElement('a');
	// downloadLink.href = window.URL.createObjectURL(blob);
	// downloadLink.download = `${fullName}.vcf`; // Nazwa pliku
	//
	// // Dodanie linku do dokumentu i symulacja kliknięcia w celu pobrania pliku
	// document.body.appendChild(downloadLink);
	// downloadLink.click();
	//
	// // Usunięcie linku po pobraniu pliku
	// document.body.removeChild(downloadLink);
}