import React, {useState} from "react";
import PhoneInput, {CountryData} from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css';
import classes from "./FormInputPhone.module.css";

interface FormInputPhoneProps {
    id?: string | undefined;
    name?: string | undefined;
    value?: string | null | undefined;
    placeholder?: string | undefined;
    required?: boolean | undefined;
    label?: string | undefined;
    isActive?: boolean | undefined;
    isTouched?: boolean | undefined;
    hasError?: boolean | undefined;
    onChange?: (
        value: string,
        data: CountryData | {},
        event: React.ChangeEvent<HTMLInputElement>,
        formattedValue: string
    ) => void;
    onFocus?: () => void | undefined;
    onBlur?: () => void | undefined;
}

const FormInputPhone: React.FC<FormInputPhoneProps> = ({
    id,
    name,
    value,
    placeholder,
    required,
    label,
    isActive,
    isTouched,
    hasError,
    onChange,
    onFocus,
    onBlur,
}) => {
    const [wasVibrated, setWasVibrated] = useState<boolean>(false);

    let fieldClassName = `${classes.field} ${classes.active}`;

    if (hasError) {
        fieldClassName += ` ${classes.error}`;
        if (!wasVibrated) {
            if ('vibrate' in navigator) {
                navigator.vibrate(200);
            }
            setWasVibrated(true);
        }
    }

    if (hasError) {
        fieldClassName += ` ${classes.error}`;
        if (!wasVibrated) {
            if ('vibrate' in navigator) {
                navigator.vibrate(200);
            }
            setWasVibrated(true);
        }
    }

    let errorMessage = ""

    if (required && value && value.trim() === "" && isTouched) {
        errorMessage = "wymagane pole."
    } else if (hasError) {
        errorMessage = "nie poprawna wartość"
    }

    const labelClassName = `${classes.label} ${hasError ? classes.error : ""}`

    return (
        <div className={fieldClassName}>
            <PhoneInput
                inputClass={classes.input}
                placeholder={label}
                specialLabel=""
                country="pl"
                inputProps={{
                    required: required,
                    id: id,
                    name: name
                }}
                dropdownStyle={{
                    fontSize: "0.75rem",
                }}
                buttonClass={classes.button}
                dropdownClass={`${classes["countries-container"]}`}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                value={value}
                countryCodeEditable={false}
            />
            <label htmlFor={id} className={labelClassName}>
                {required ? "*" : ""}{label}{hasError ? " - " + errorMessage : ""}
            </label>
        </div>
    );
};

export default FormInputPhone;