import React from "react";

import classes from "./SVGButton.module.css"

interface ButtonSVGProps {
    children: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
}

const SVGButton: React.FC<ButtonSVGProps> = ({ onClick, children, className=""}) => {
    return <button className={`${classes["button-svg"]} ${className}`} onClick={onClick}>
        {children}
    </button>;
}

export default SVGButton;