import React from "react";

import classes from "./ProfileFooter.module.css";

import imgLogo from "../../../assets/logo-black.png";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {IRoot} from "../../../schemas/Lang/Root";

interface UserProfileFooterProps {
}

const ProfileFooter: React.FC<UserProfileFooterProps> = () => {
    const { t } = useTranslation();
    const paths = t('paths', { returnObjects: true }) as IRoot["paths"];
    const mainPage = paths["mainPage"];

    return <div className={classes.container}>
        <Link className={classes.link} to={mainPage}>
            <img className={classes.logo} src={imgLogo} alt="YooCard" />
        </Link>
    </div>;
}

export default ProfileFooter;