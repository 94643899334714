import React, {useCallback, useState} from 'react';

import classes from "./UserPicture.module.css"
import {AnyTile} from "../../schemas/Tile";
import UserPictureActionsModal from "./UserPictureActionsModal";
import {useAppSelector} from "../../hooks/hooks";
import {selectUser} from "../../store/slices/userSlice";
import IProfile from "../../schemas/Profile";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {IRoot} from "../../schemas/Lang/Root";

interface UserAvatarProps {
    profile: IProfile;
    onClick?: () => void;
    className?: string;
}

const UserPicture: React.FC<UserAvatarProps> = ({profile, onClick, className = ""}) => {
    const [holdTimer, setHoldTimer] = useState<NodeJS.Timeout | null>(null);
    const currentUser = useAppSelector(selectUser);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const translationPaths = t('paths', { returnObjects: true }) as IRoot["paths"];

    const handlePictureHoldStart = useCallback(() => {
        const timer = setTimeout(() => navigate(translationPaths.profile.photo.index), 500);
        setHoldTimer(timer);
    }, []);

    const handlePictureHoldEnd = useCallback(() => {
        if (holdTimer) {
            clearTimeout(holdTimer);
            setHoldTimer(null);
        }
    }, [holdTimer]);


    let containerClassName = className ? `${classes.container} ${className}` : classes.container;

    if (onClick) {
        containerClassName += " " + classes.clickable
    }

    let content = <p className={classes.text}>{profile.username[0].toUpperCase()}</p>

    if (profile.pictureUrl) {
        content = <img className={classes.picture} src={profile.pictureUrl} alt={`Profile of the ${profile.username}`}/>
    }

    return <div
        className={containerClassName}
        onMouseDown={!!currentUser ? handlePictureHoldStart : undefined}
        onMouseUp={!!currentUser ? handlePictureHoldEnd : undefined}
        onTouchStart={!!currentUser ? handlePictureHoldStart : undefined}
        onTouchEnd={!!currentUser ? handlePictureHoldEnd : undefined}
        onContextMenu={!!currentUser ? (e) => e.preventDefault() : undefined}
        onClick={onClick}
    >
        {content}
    </div>
};

export default UserPicture;