import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { getCookie } from '../../utils/cookie';
import {IAuth} from "../../schemas/Auth";
import {RootState} from "../store";

// const initialState = null as IAuth | null;
//
// const authSlice = createSlice({
//     name: 'auth',
//     initialState,
//     reducers: {
//         setAuthExpireIn: (state, action: PayloadAction<IAuth>) => {
//             return action.payload;
//         },
//         clearAuthExpireIn: (state) => {
//             return null;
//         }
//
//         // checkUserLoggedIn: (state) => {
//         //     const token = getCookie('is_logged_in');
//         //     state.isLoggedIn = !!token;
//         // },
//     },
// });

const initialState: IAuth = {
    isUserAuthenticated: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsUserAuthenticated: (state, action: PayloadAction<IAuth>) => {
            return action.payload;
        },
        clearAuth: () => {
            return initialState
        }
    },
});

export const {
    setIsUserAuthenticated,
    clearAuth
} = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;