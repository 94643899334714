import React, {useState} from "react";
import {ActionFunction, LoaderFunction, redirect, useNavigate, useParams} from "react-router-dom";
import ModalFragmentHeader from "../../../components/Modals/ModalFragmentHeader";
import classes from "../../../components/Tiles/NewTileFormModal.module.css";
import ModalOptionList from "../../../components/Modals/ModalOptionList";
import ModalOption from "../../../components/Modals/ModalOption";
import {AnyTileCreate, TileTypeEnum} from "../../../schemas/Tile";
// import TileForm from "../../../components/Tiles/Forms/TileForm";
import TileForm from "../../../newcomponents/Tiles/Form/TileForm";
import ModalResponsive from "../../../newcomponents/Modals/ModalResponsive";
import services from "../../../services/Services";
import {useTranslation} from "react-i18next";
import {IRoot} from "../../../schemas/Lang/Root";
import i18n from "../../../i18nConfig";

const NewTilePage: React.FC = () => {
    const [tileType, setTileType] = useState<TileTypeEnum | null>(null);
    const navigate = useNavigate();
    const { username } = useParams() as { username: string };
    const { t } = useTranslation();
    const translationForms = t('forms', { returnObjects: true }) as IRoot["forms"];
    const translationPaths = t('paths', { returnObjects: true }) as IRoot["paths"];

    let headerTileForm = ""

    switch (tileType) {
        case TileTypeEnum.header:
            headerTileForm = translationForms.tiles.overlayHeaders.tileNew.section
            break
        case TileTypeEnum.contact:
            headerTileForm = translationForms.tiles.overlayHeaders.tileNew.contact
            break
        case TileTypeEnum.url:
            headerTileForm = translationForms.tiles.overlayHeaders.tileNew.link
            break
    }

    if (tileType) {
        return <ModalResponsive
            onClose={() => navigate(`/${translationPaths.profile.index}/${username}`)}
        >
            <ModalFragmentHeader className={classes.header} onReturn={() => setTileType(null)}>
                {headerTileForm}
            </ModalFragmentHeader>
            <TileForm
                method="POST"
                type={tileType}
            />
        </ModalResponsive>
    }

    return <ModalResponsive onClose={() => navigate(`/${translationPaths.profile.index}/${username}`)}>
        <ModalFragmentHeader className={classes.header} onClose={() => navigate("../..")}>
            {translationForms.tiles.overlayHeaders.newTile}
        </ModalFragmentHeader>
        <ModalOptionList>
            <ModalOption onClick={() => setTileType(TileTypeEnum.header)}>{translationForms.tiles.typeText.section}</ModalOption>
            <ModalOption onClick={() => setTileType(TileTypeEnum.contact)}>{translationForms.tiles.typeText.contact}</ModalOption>
            <ModalOption onClick={() => setTileType(TileTypeEnum.url)}>{translationForms.tiles.typeText.link}</ModalOption>
        </ModalOptionList>
    </ModalResponsive>
}

export default NewTilePage;

export const loader: LoaderFunction = async ({request, params}) => {
    return null;
}

export const action: ActionFunction = async ({request, params}) => {
    const tileData: AnyTileCreate = await request.json();
    if (tileData.userId) {
        await services.tiles.createByAdmin(tileData.userId, tileData)
    } else {
        await services.tiles.create(tileData)
    }
    return redirect(`/${i18n.t("paths.profile.index")}/${params.username}`)
}