import React, {useState} from "react";
import Card from "../UI/Card";
import {Form, json, Link, useActionData, useAsyncError, useNavigation, useRouteError} from "react-router-dom";
import useInput from "../../hooks/use-input";
import FormInput from "../UI/Inputs/FormInput";

import classes from "./AuthForm.module.css";
import FormButton from "../UI/Buttons/FormButton";
import {ActionFunction} from "@remix-run/router/utils";
import services from "../../services/Services";
import useFormInput from "../../hooks/use-form-input";
import FormInput2 from "../UI/Inputs/FormInput2";
import {isTileTitleValid} from "../../validators/Tile";
import {isUserLoginValid, isUserPasswordValid} from "../../validators/User";
import {IAuthLogin} from "../../schemas/Auth";
import ModalInfo from "../Modals/ModalInfo";

interface LoginFormProps {
    className?: string
}

const ResetPasswordForm: React.FC<LoginFormProps> = ({className}) => {
    const [isLoginValid, setIsLoginValid] = useState<boolean>(false);
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const [showOverlay, setShowOverlay] = useState<boolean>(true);
    const actionData = useActionData() as {detail: string};
    const navigation = useNavigation();
    const isSubmitting = navigation.state === "submitting";
    const isValidForm = isLoginValid && isPasswordValid;

    return <Form method="POST" className={className}>
        {showOverlay && <ModalInfo onClose={() => setShowOverlay(false)}>
            <p className={classes.info}>
                Wprowadź swój adres e-mail lub nazwę użytkownika, a my wyślemy Ci aktywny link, który umożliwi odzyskanie dostępu do konta.
                <br/>
                <br/>
                <span className={classes["prime-info"]}>!UWAGA!</span>
                <br/>
                <span className={classes["prime-info"]}>Link aktywny będzie tylko przez 24 godziny.</span>
            </p>
        </ModalInfo>}
        <h2 className={classes.header}>Resetowanie hasła</h2>
        <div className={classes["form-elements"]}>
            <FormInput2
                id="login"
                name="login"
                label="Nazwa użytkownika/E-mail"
                onValidationStatusChange={(isError) => setIsLoginValid(!isError)}
                onValidate={isUserLoginValid}
            />
            {actionData && <p className={classes.error}>{actionData && actionData.detail}</p>}
            <FormButton type="submit" disabled={!isValidForm}>Wyślij link do logowania</FormButton>
            <p className={classes["switch-mode"]}>Nie masz konta? <Link to="/rejestracja">Zarejestruj się!</Link></p>
            <div className={classes.choice}>
                <div className={classes.separator}/>
                <div>LUB</div>
                <div className={classes.separator}/>
            </div>
            <Link className={classes["switch-mode"]} to="/logowanie">Powrót do logowania</Link>
        </div>
    </Form>
}

export default ResetPasswordForm;