import React from "react";
import ModalOverlay from "./ModalOverlay";
import SVGLoading from "../Icons/Loading";

import classes from "./ModalLoading.module.css";

interface ModalLoadingProps {
    className?: string;
}

const ModalLoading: React.FC<ModalLoadingProps> = ({className}) => {
    const overlayClassName = className ? `${classes.modal} ${className}` : classes.modal
    return <ModalOverlay className={overlayClassName}>{<SVGLoading className={classes.icon}/>}</ModalOverlay>;
}

export default ModalLoading;