import React from "react";
import classes from "./GuestNavMenu.module.css";
import ModalFragmentHeader from "../../../Modals/ModalFragmentHeader";
import ModalOptionList from "../../../Modals/ModalOptionList";
import ModalOption from "../../../Modals/ModalOption";
import ToggleSwitchButton from "../../../UI/Buttons/ToggleSwitchButton";
import ModalResponsive from "../../../Modals/ModalResponsive";
import ShareButton from "../../../UI/Buttons/ShareButton";
import Menu from "../../../Icons/Menu";
import MenuIcon from "../../../Icons/Menu";
import Return from "../../../Icons/Return";
import SVGReturn from "../../../Icons/Return";
import SignalLogo from "../../../Icons/SignalLogo";
import SVGShare from "../../../Icons/Share";
import {useTranslation} from "react-i18next";
import {IRoot} from "../../../../schemas/Lang/Root";

interface GuestNavMenuProps {
	onClose: () => void;
}

const GuestNavMenu: React.FC<GuestNavMenuProps> = ({
	onClose,
}) => {
	const { t } = useTranslation();
	const translationPaths = t('paths', { returnObjects: true }) as IRoot["paths"];
	const translationNavMenu = t('navMenu', { returnObjects: true }) as IRoot["navMenu"];

	return <ModalResponsive onClose={onClose}>
		<ModalFragmentHeader className={classes.header} onClose={onClose}>YooCard</ModalFragmentHeader>
		<div className={classes["share-button-wrapper"]}>
			<ShareButton className={classes["share-button"]} SVGStart={<SVGShare/>}>{translationNavMenu.guess.shareProfile}</ShareButton>
		</div>
		<div className={classes.container}>
			<h4 className={classes.title}>{translationNavMenu.guess.header}</h4>
			<p className={classes.subtitle}>{translationNavMenu.guess.subheader}</p>
		</div>
		<div className={`${classes.container} ${classes["container-buttons"]}`}>
			<a className={classes["button-link"]} href={`/${translationPaths.login.index}`}>{translationNavMenu.guess.login}</a>
			<a className={classes["button-link"]} href={`/${translationPaths.signup.index}`}>{translationNavMenu.guess.signup}</a>
		</div>
	</ModalResponsive>
}

export default GuestNavMenu;