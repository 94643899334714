import React from "react";
import IHeaderTile from "../../../schemas/TileExtensions/HeaderTile";
import TileContainer from "../Elements/TileContainer";

import classes from "./HeaderTile.module.css";


const HeaderTile: React.FC<IHeaderTile> = ({
	id,
	type,
	title,
	position,
	shortId,
	userId,
	active
}) => {
	const className = `${classes.container} ${active ? classes.active : classes.disabled}`
	return <TileContainer className={className} active={active}>
		<h4 className={classes.title}>{title}</h4>
	</TileContainer>
}

export default HeaderTile;