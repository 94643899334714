import React from "react";
import {isRouteErrorResponse, useRouteError} from "react-router-dom";

import classes from "./Error.module.css";

interface ErrorPageProps {
}

const ErrorPage: React.FC<ErrorPageProps> = () => {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        if (error.status === 401) {
            // ...
        }
        else if (error.status === 404) {
            // ...
        }

        return (
            <div className={classes.error} id="error-page">
                <h1>Oops! {error.status}</h1>
                <p>{error.statusText}</p>
                {error.data?.message && (
                    <p>
                        <i>{error.data.message}</i>
                    </p>
                )}
            </div>
        );
    } else if (error instanceof Error) {
        return (
            <div className={classes.error} id="error-page">
                <h1>Oops! Niespodziewany błąd.</h1>
                <p>Coś poszło nie tak.</p>
                <p>
                    <i>{error.message}</i>
                </p>
            </div>
        );
    } else {
        return <></>;
    }
}

export default ErrorPage;