import React, {ReactNode} from "react";
import {AnyTile} from "../../../schemas/Tile";

import classes from "./TileContainer.module.css";

interface TileContainerProps {
    active: AnyTile["active"];
    children: ReactNode;
    className?: string;
}

const TileContainer: React.FC<TileContainerProps> = ({active, children, className}) => {
    let containerClassName = `${classes.container} ${active ? classes.active : classes.disabled}`

    if (className) {
        containerClassName += ` ${className}`
    }

    return <div className={containerClassName}>
        {children}
    </div>
}

export default TileContainer;