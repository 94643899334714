export const isUserUsernameValid = (value: string): boolean => {
	const regex = /^[a-zA-Z0-9_.-]+$/;
	return value.trim().length >= 2 && value.trim().length <= 64 && regex.test(value);
};

export const isUserFullNameValid = (value: string): boolean => value.trim().length >= 2 && value.trim().length <= 64;

export const isUserEmailValid = (value: string): boolean => {
	return Boolean(String(value)
	.toLowerCase()
	.match(
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	));
};

export const isUserPasswordValid = (value: string): boolean => {
	const v = value.trim();
	// const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,64}$/;
	// const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,32}$/;
	const regex = /^[a-zA-Z0-9!@#$%^&*()_+=\-{}\[\];':",./<>?].{8,32}$/;
	return regex.test(v);
};

export const isUserPasswordReplacedValid = (value1: string, value2: string): boolean => {
	return value1 === value2 && isUserPasswordValid(value1) && isUserPasswordValid(value2);
};

export const isUserLoginValid = (value: string): boolean => {
	const regex = /^.{2,}$/;
	return regex.test(value);
}

// export const isUserPhoneNumberValid = (value: string): boolean => {
// 	const regex = /^\+?[1-9]\d{1,14}$/
// 	return regex.test(value);
// }

const createRegexFromFormat = (format: string): RegExp => {
	const escapedFormat = format.replace(/ /g, '').replace(/-/g, '').replace(/^\+/, '').replace(/\./g, '\\d');
	return new RegExp('^' + escapedFormat + '$');
}

const createRegexFromFormatPrefix = (format: string): RegExp => {
	const escapedFormat = format.replace(/ /g, '').replace(/-/g, '').replace(/^\+/, '').replace(/\./g, '\\d');
	return new RegExp('^' + escapedFormat + '$');
}

export const isUserPhoneNumberValid = (phoneNumber: string, format: string): boolean => {
	const regexPrefix = createRegexFromFormatPrefix(format);
	const regex = createRegexFromFormat(format);
	return regex.test(phoneNumber);
}

export const isUserPhoneNumberValidOrEmpty = (phoneNumber: string, format: string): boolean => {
	// Sprawdzamy, czy numer telefonu jest pusty
	if (!phoneNumber.trim()) return true;
	if (format === "") return true;

	let dotCount = 0;
	for (let i = 0; i < format.length; i++) {
		if (format[i] === ' ') break;
		if (format[i] === '.') dotCount++;
	}

	if (phoneNumber.length === dotCount) return true;
	return isUserPhoneNumberValid(phoneNumber, format);
};