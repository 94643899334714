import axios, {AxiosError, AxiosInstance, AxiosResponse} from "axios";
import {json} from "react-router-dom";
import IUser, {IUserCreate} from "../schemas/User";
import {IAuthLogin, IAuthData} from "../schemas/Auth";

interface AuthServiceInterface {
    // login: (data: FormData) => Promise<AxiosResponse<{ detail: string; }, any>>;
    login: (data: FormData) => Promise<{detail: string}>;

    logout: () => Promise<{detail: string}>;
    register: (data: FormData) => Promise<any>;

    verify: (token: string) => Promise<{detail: string}>;
    resendVerify: () => Promise<{detail: string}>;
    // isAuthenticated(): boolean;
}

class AuthService implements AuthServiceInterface {
    config;

    constructor(private axiosInstance: AxiosInstance) {
        this.config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
            },
            withCredentials: true
        }
    }

    // async login(data: FormData): Promise<AxiosResponse<{ detail: string; }, any>> {
    async login(data: FormData): Promise<{ detail: string; }> {
        try {
            const response = await this.axiosInstance.post<{ detail: string }>("/auth/login", data, this.config);
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) {
                    throw json({detail: "Nie poprawne dane logowania", status: 401}, {status: 401})
                }
            }

            throw json({detail: "Niestety coś poszło nie tak", status: 401}, {status: 500})
        }


        // try {
        //     return await this.axiosInstance.post<{ detail: string }>("/auth/login", data, this.config);
        // } catch (error) {
        //     if (axios.isAxiosError(error)) {
        //         // throw error.response
        //         throw new Response(JSON.stringify({detail: "Something went wrong."}), {status: 500})
        //     }
        //
        //     throw new Response(JSON.stringify({detail: "Something went wrong."}), {status: 500})
        // }


    // async login(data: FormData): Promise<{ detail: string }> {
        // try {
        //     const response = await this.axiosInstance.post<IAuthData>("/auth/login", data, {
        //         headers: {
        //             "Content-Type": "application/x-www-form-urlencoded",
        //             "Accept": "application/json",
        //         },
        //         withCredentials: true
        //     });
        //     // return response.data; TODO: Uncomment in qu domain
        //     return {detail: "Logowanie przebiegło pomyśnie."}, {status: response.status})
        // } catch (err) {
        //     const axiosError = err as AxiosError;
        //     if (axiosError.response?.data) {
        //         if (axiosError.response.status === 401) {
        //             throw json({detail: "Nie poprawne dane logowania"}, {status: 401})
        //         }
        //     }
        //     throw json({detail: "Błąd serwera."}, {status: 500})
        // }
    }

    async logout(): Promise<{detail: string}> {
        try {
            const response = await this.axiosInstance.post<{detail: string}>(
                "/auth/logout",
                {},
                this.config
            )
            return response.data
        } catch (err) {
            throw Error("Błąd serwera.")
        }
    }

    async register(data: FormData): Promise<IAuthData> {
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
            }
        }

        try {
            const response = await this.axiosInstance.post<IAuthData>(
                "/auth/register",
                data,
                config
            )
            return response.data
        } catch (err) {
            const axiosError = err as AxiosError;
            if (axiosError.response?.data) {
                if (axiosError.response.status === 409) {
                    throw Error("Użytkownik o podanych danych już istnieje.")
                }
            }
            throw Error("Błąd serwera.")

            // console.log(error.status)
            // if (!error.response || !(error.response.data instanceof Object)) {
            //     throw json({detail: "Something went wrong."}, {status: 500})
            // }
            // if (error.response.status === 409) {
            //     console.log(error.response)
            //     throw json({...error.response.data}, {status: 400});
            // }
            // throw json({detail: "Unexpected error"}, {status: 500})
        }
    }

    // isAuthentication(): boolean {
    //     const token = document.cookie.split(';').some((item) => item.trim().startsWith('access_token='));
    //     return !!token;
    // }

    async verify(token: string): Promise<{ detail: string; }> {
        try {
            const response = await this.axiosInstance.get(`/auth/verify_email/${token}`, this.config)
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) {
                    throw json({detail: "Nie poprawne dane logowania", status: 401}, {status: 401})
                }
            }

            throw json({detail: "Niestety coś poszło nie tak", status: 401}, {status: 500})
        }
    }

    async resendVerify(): Promise<{detail: string; }> {
        try {
            const response = await this.axiosInstance.post(`/auth/resend_verify_email`, {}, this.config)
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) {
                    throw json({detail: "Nie poprawne dane logowania", status: 401}, {status: 401})
                }
            }

            throw json({detail: "Niestety coś poszło nie tak", status: 401}, {status: 500})
        }
    }
}

export default AuthService;