import React, {useEffect} from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {setUserAction} from "../../store/actions/userActions";
import {selectUser} from "../../store/slices/userSlice";
import {selectAuth} from "../../store/slices/authSlice";
import {clearAuthAction} from "../../store/actions/authActions";
import services from "../../services/Services";

const RootLayout: React.FC = () => {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectUser);
    const {isUserAuthenticated} = useAppSelector(selectAuth);

    useEffect(() => {
        if (!isUserAuthenticated && !currentUser) return;

        (async () => {
            try {
                const user = await services.account.getInfo()
                dispatch(setUserAction(user))
            } catch (e) {
                console.error("Nie można uwierzytelnić użytkownika")
                dispatch(clearAuthAction())
            }
        })()
    }, [isUserAuthenticated]);

    return <>
        <Outlet />
        {currentUser && !currentUser.isVerified && <Navigate to={`/profil/${currentUser.username}/weryfikacja`}/>}
    </>
}


export default RootLayout;