import React from "react";

import classes from "./Welcome.module.css";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";

interface WelcomePageProps {
}

const WelcomePage: React.FC<WelcomePageProps> = () => {
    const dispatch = useAppDispatch();
    const userData = useAppSelector((state: any) => state.user);
    return <div className={classes.container}>
        <h1>Witaj w YooCard!</h1>

        <h3>Dziękujemy za rejestrację!</h3>

        <p>
            Wysłaliśmy wiadomość z linkiem aktywacyjnym na adres {userData.email}.
        </p>

        <p>
            Jeśli napotkasz jakiekolwiek problemy lub masz pytania, nasz zespół wsparcia jest gotowy pomóc.
            Skontaktuj się z nami przez buttonbok@yoocard.pl lub +48 543 511 824.
        </p>

        <p>Cieszymy się, że dołączyłeś do naszej społeczności!</p>

        <p>Z poważaniem,</p>
        <p>Zespół YooCard</p>
    </div>;
}

export default WelcomePage;