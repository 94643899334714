import React, {useReducer} from "react";

export interface useFormInputUrlProps {
    initialValue?: string;
    validateValue?: (value: string) => boolean;
}

export interface UseFormInputInterface {
    (props: useFormInputUrlProps): {
        value: string;
        isTouched: boolean;
        isActive: boolean;
        isValid: boolean;
        hasError: boolean;
        handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
        handleInputBlur: () => void;
        handleInputFocus: () => void;
        reset: () => void;
    };
}

interface InitialStateInterface {
    value: string;
    isTouched: boolean;
    isActive: boolean;
}


const initialState: InitialStateInterface = {
    value: "",
    isTouched: false,
    isActive: false,
}

interface ActionStateInterface extends Partial<InitialStateInterface> {
    type: string;
}

const inputStateReducer = (state: InitialStateInterface, action: ActionStateInterface): InitialStateInterface => {
    switch (action.type) {
        case "CHANGE":
            return { ...state, value: action.value || "", isActive: true};
        case "BLUR":
            return { ...state, isTouched: true, isActive: false}
        case "FOCUS":
            return { ...state, isActive: true}
        case "RESET":
            return { ...initialState }
        default:
            return { ...state }
    }
}


const useFormInputUrl: UseFormInputInterface = ({
    initialValue = "",
    validateValue
}) => {
    const [inputState, dispatch] = useReducer(inputStateReducer, { ...initialState, value: initialValue });

    let valueIsValid = true;
    if (validateValue) {
        valueIsValid = validateValue(inputState.value);
    }

    const hasError = !valueIsValid && inputState.isTouched;

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let updatedValue = event.currentTarget.value;
        if (updatedValue && !updatedValue.startsWith('https://')) {
            updatedValue = `https://${updatedValue}`;
        }
        dispatch({ type: "CHANGE", value: updatedValue });
    };

    const handleInputBlur = () => {
        dispatch({ type: "BLUR" });
    };

    const handleInputFocus = () => {
        dispatch({ type: "FOCUS" });
    };

    const reset = () => {
        dispatch({ type: "RESET" });
    };

    return {
        value: inputState.value,
        isTouched: inputState.isTouched,
        isActive: inputState.isActive,
        isValid: valueIsValid,
        hasError,
        handleInputChange,
        handleInputBlur,
        handleInputFocus,
        reset,
    };
};

export default useFormInputUrl;