import React from "react";
import IUrlTile from "../../../schemas/TileExtensions/UrlTile";
import TileContainer from "../Elements/TileContainer";
import TileIcon from "../Elements/TileIcon";
import TileTitle from "../Elements/TileTitle";
import TileShareButton from "../Elements/TileShareButton";

import classes from "./UrlTile.module.css";

const UrlTile: React.FC<IUrlTile> = ({
	id,
	iconUrl,
	url,
	active,
	title,
	userId,
	shortId,
}) => {
	const className = `${classes.container} ${active ? classes.active : classes.disabled}`

	return <TileContainer className={className} active={active}>
		<a className={classes.url} href={url}>
			<TileIcon iconUrl={iconUrl}/>
			<TileTitle title={title}/>
		</a>
		{/*{active && <TileShareButton tileId={id}/>}*/}
	</TileContainer>
}

export default UrlTile;