import React, {useEffect, useReducer} from "react";
import {IAvailability} from "../schemas/common";

interface UseFormInputProps {
    initialValue?: string;
    validateValue?: (value: string) => boolean;
    checkAvailability?: (value: string) => Promise<IAvailability>;
}

interface UseFormInputInterface {
    (props: UseFormInputProps): {
        value: string;
        isTouched: boolean;
        isActive: boolean;
        isValid: boolean;
        isAvailable: boolean;
        hasError: boolean;
        handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
        handleInputBlur: () => void;
        handleInputFocus: () => void;
        reset: () => void;
    };
}

interface InitialStateInterface {
    value: string;
    isTouched: boolean;
    isActive: boolean;
    isAvailable: boolean;
}


const initialState: InitialStateInterface = {
    value: "",
    isTouched: false,
    isActive: false,
    isAvailable: false,
}

interface ActionStateInterface extends Partial<InitialStateInterface> {
    type: string;
}

const inputStateReducer = (state: InitialStateInterface, action: ActionStateInterface): InitialStateInterface => {
    switch (action.type) {
        case "CHANGE":
            return { ...state, value: action.value || "", isActive: true, isAvailable: true};
        case "BLUR":
            return { ...state, isTouched: true, isActive: false}
        case "FOCUS":
            return { ...state, isActive: true}
        case "RESET":
            return { ...initialState }
        case "API":
            return { ...state, isAvailable: action.isAvailable as boolean}
        default:
            return { ...state }
    }
}

const useFormInputAPI: UseFormInputInterface = ({
    initialValue="",
    validateValue,
    checkAvailability
}) => {
    const [inputState, dispatch] = useReducer(inputStateReducer, { ...initialState, value: initialValue });

    let valueIsValid = inputState.isAvailable;
    if (validateValue) {
        valueIsValid = validateValue(inputState.value);
    }

    const hasError = !valueIsValid && inputState.isTouched || !inputState.isAvailable && inputState.isTouched;

    useEffect(() => {
        if (inputState.value.trim() === "") return;

        const timer = setTimeout(async () => {
            if (checkAvailability) {
                const { isAvailable } = await checkAvailability(inputState.value) as IAvailability
                dispatch({ type: "API", isAvailable: isAvailable})
            }
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, [inputState.value]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: "CHANGE", value: event.currentTarget.value})
    }

    const handleInputBlur = () => {
        dispatch({ type: "BLUR" })
    }

    const handleInputFocus = () => {
        dispatch({ type: "FOCUS" })
    }

    const reset = () => {
        dispatch({ type: "RESET" })
    }

    return {
        value: inputState.value,
        isTouched: inputState.isTouched,
        isActive: inputState.isActive,
        isValid: valueIsValid,
        isAvailable: inputState.isAvailable,
        hasError,
        handleInputChange,
        handleInputBlur,
        handleInputFocus,
        reset,
    }
}

export default useFormInputAPI