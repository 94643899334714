import IContactTile, {IContactTileCreate, IContactTileUpdate} from "./TileExtensions/ContactTile";
import IUrlTile, {IUrlTileCreate, IUrlTileUpdate} from "./TileExtensions/UrlTile";
import IHeaderTile, {IHeaderTileCreate, IHeaderTileUpdate} from "./TileExtensions/HeaderTile";

export enum TileTypeEnum {
	header = "header",
	contact = "contact",
	url = "url",
}

export interface ITileBase {
	// type: TileTypeEnum;
	title: string;
}

export interface ITileCreate extends ITileBase {
	position?: number;
	userId?: string
}

export interface ITileUpdate extends Partial<ITileBase> {
	position?: number;
	userId?: string
}

export default interface ITile extends ITileBase {
	id: string;
	position: number;
	shortId?: string;
	userId: string;
	active: boolean;
}

export type AnyTile = IHeaderTile | IContactTile | IUrlTile;
export type AnyTileCreate = IHeaderTileCreate | IContactTileCreate | IUrlTileCreate;
export type AnyTileUpdate = IHeaderTileUpdate | IContactTileUpdate | IUrlTileUpdate;