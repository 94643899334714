import React, {useEffect, useState} from "react";
import {
    defer, json, Navigate,
    Outlet,
    redirect,
    Route,
    Routes,
    useActionData,
    useLoaderData,
    useLocation, useNavigate,
    useRoutes
} from "react-router-dom";
import {ActionFunction} from "@remix-run/router/utils";

import classes from "./NewUser.module.css";
import CreateUserForm, {userFormAction, userFormLoader} from "../../../components/Users/CreateUserForm";
import UserPictureForm from "../../../components/Users/UserPictureForm";
import services from "../../../services/Services";
import {AxiosError, AxiosResponse, isAxiosError} from "axios";
import {IUserCreate, UserRoleEnum} from "../../../schemas/User";
import {useAppSelector} from "../../../hooks/hooks";
import {selectUser} from "../../../store/slices/userSlice";
import {useTranslation} from "react-i18next";
import {IRoot} from "../../../schemas/Lang/Root";
import i18n from "../../../i18nConfig"

interface NewUserPageProps {
}

const NewUserPage: React.FC<NewUserPageProps> = () => {
    const currentUser = useAppSelector(selectUser);

    if (currentUser === null) {
        return <Navigate to="/" replace={true}/>
    }

    if (![UserRoleEnum.admin, UserRoleEnum.superAdmin].includes(currentUser.role)) {
        return <Navigate to="/" replace={true}/>
    }

    return <div className={classes.container}>
        <CreateUserForm/>
    </div>;
}

export default NewUserPage;

export const action: ActionFunction = async ({ request, params, context}) => {
    const formData = await request.formData();
    const user = await services.users.create(formData)
    return redirect(`/${i18n.t("paths.profile.index")}/${user.data.username}`);
}
