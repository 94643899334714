import React from "react";
import ModalResponsive from "./ModalResponsive";
import ModalFragmentHeader from "./ModalFragmentHeader";
import FormButton from "../UI/Buttons/FormButton";

import classes from "./ModalInfo.module.css";

interface ModalInfoProps {
    children: React.ReactNode
    onClose: () => void;
}

const ModalInfo: React.FC<ModalInfoProps> = ({children, onClose}) => {
    return <ModalResponsive>
        <ModalFragmentHeader className={classes.header}>
            Informacja
        </ModalFragmentHeader>
        {children}
        <FormButton onClick={onClose}>Rozumiem</FormButton>
    </ModalResponsive>
}

export default ModalInfo;