import React, {ButtonHTMLAttributes} from "react";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../store/store";
import {useAppSelector} from "../../../../hooks/hooks";
import {selectIsTilesDraggable, setTilesDraggable} from "../../../../store/slices/tileDraggableSlice";
import {clearUser, selectUser} from "../../../../store/slices/userSlice";
import ModalResponsive from "../../../Modals/ModalResponsive";
import classes from "../MainSettingsModal.module.css";
import ModalFragmentHeader from "../../../Modals/ModalFragmentHeader";
import ModalOptionList from "../../../Modals/ModalOptionList";
import ModalOption from "../../../Modals/ModalOption";
import ToggleSwitchButton from "../../../UI/Buttons/ToggleSwitchButton";
import services from "../../../../services/Services";
import {clearUserAction} from "../../../../store/actions/userActions";
import {useNavigate, useSubmit} from "react-router-dom";
import {clearAuthAction} from "../../../../store/actions/authActions";
import {useTranslation} from "react-i18next";
import {IRoot} from "../../../../schemas/Lang/Root";

interface UserNavMenuProps {
	onClose: () => void;
}

const UserNavMenu: React.FC<UserNavMenuProps> = ({
	onClose
}) => {
	const dispatch = useDispatch<AppDispatch>()
	const isTilesDraggable = useAppSelector(selectIsTilesDraggable);
	const currentUser = useAppSelector(selectUser)
	const navigate = useNavigate();
	const submit = useSubmit()
	const { t } = useTranslation();
	const translationPaths = t('paths', { returnObjects: true }) as IRoot["paths"];
	const translationNavMenu = t('navMenu', { returnObjects: true }) as IRoot["navMenu"];

	const handleToggleDraggable = (state: boolean) => {
		dispatch(setTilesDraggable(state))
	}

	const handleLogout = async (event: React.MouseEvent<HTMLButtonElement>) => {
		submit({}, {action: `/${translationPaths.logout.index}`, method: "POST"})
		// await services.auth.logout()
		// dispatch(clearUserAction())
		// dispatch(clearAuthAction())
		// if (currentUser) {
		// 	navigate("/logowanie", {state: {username: currentUser.username}})
		// }
	}

	return <ModalResponsive className={classes.container} onClose={onClose}>
		<ModalFragmentHeader onClose={onClose}>{translationNavMenu.user.header}</ModalFragmentHeader>
		<ModalOptionList>
			<ModalOption onClick={() => null}>{translationNavMenu.user.textButtons.appearance}</ModalOption>
			{/*<ModalOption onClick={() => null}>{translationNavMenu.user.textButtons.account}</ModalOption>*/}
			{/*<ModalOption onClick={() => null}>{translationNavMenu.user.textButtons.help}</ModalOption>*/}
		</ModalOptionList>
		<ToggleSwitchButton
			id="edit-tiles"
			name="edit-tiles"
			label={translationNavMenu.user.labels.enableDraggingTiles}
			className={classes["toggle-button"]}
			initialValue={isTilesDraggable}
			onToggle={handleToggleDraggable}
		/>
		<ModalOptionList>
			<ModalOption className={classes.logout} onClick={handleLogout}>{translationNavMenu.logout}</ModalOption>
		</ModalOptionList>
	</ModalResponsive>
}

export default UserNavMenu;