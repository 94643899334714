import React, {useContext} from "react";
import ModalResponsive from "../../../components/Modals/ModalResponsive";
import {
    ActionFunction,
    LoaderFunction,
    matchPath, matchRoutes, Navigate,
    Outlet, redirect, Route, Routes, useLoaderData,
    useLocation,
    useNavigate, useOutletContext, useParams, useRouteLoaderData, useSearchParams
} from "react-router-dom";
import {TileContext} from "../../../context/TileContext";
import IProfile from "../../../schemas/Profile";
import NewTile from "./NewTile";
import {useAppSelector} from "../../../hooks/hooks";
import {selectUser} from "../../../store/slices/userSlice";
import {UserRoleEnum} from "../../../schemas/User";

interface TilePageProps {}

const TilePage: React.FC<TilePageProps> = ({}) => {
    const currentUser = useAppSelector(selectUser);
    const { username } = useParams() as { username: string };
    const navigate = useNavigate();

    if (currentUser === null) {
        return <Navigate to={".."}/>
    }

    if (currentUser.username !== username && ![UserRoleEnum.admin, UserRoleEnum.superAdmin].includes(currentUser.role)) {
        return <Navigate to={".."}/>
    }

    return <Outlet/>
    // return <ModalResponsive onClose={() => navigate(`/profil/${username}`)}>
    //     <Outlet/>
    // </ModalResponsive>
}

export default TilePage;

export const loader: LoaderFunction = async ({request, params}) => {
    const url = new URL(request.url);
    if (matchPath("/profil/:username/kafelek", url.pathname)) {
        return redirect(`/profil/${params.username}`)
    }
    return null;
}

export const action: ActionFunction = async () => {
    return;
}