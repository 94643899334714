import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import IUser from "../../schemas/User";

export const initialState = null as IUser | null;

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<IUser>): IUser  => {
            return action.payload;
        },
        clearUser: () => initialState,
    },
});

export type UserAction = ReturnType<typeof setUser | typeof clearUser>;

export const { setUser, clearUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;